import { ConfirmationModalTrigger } from 'components/confirmation-modal-trigger'
import React from 'react'
import { Draggable, type DraggableProvided } from 'react-beautiful-dnd'
import { type Action } from 'types'

import './workflow-editor.css'

interface ActionItemProps {
  action: Action
  index: number
  viewMode: boolean
  onActionDelete: (id: string) => void
  onActionClick: () => void
}

const getActionType = (action: Action): string => {
  switch (action.type) {
    case 'API_CALL': {
      return 'API Call'
    }
    case 'BULK_API_CALL': {
      return 'Bulk API Call'
    }
    default: {
      return ''
    }
  }
}

export const ActionItem = ({ action, index, viewMode, onActionDelete, onActionClick }: ActionItemProps) => {
  return (
        <Draggable draggableId={action.name} index={index}>
            {(provided: DraggableProvided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="m-4 bg-gray-50 border border-gray-300 rounded-lg shadow flex md:w-[600px]"
                >
                  <div className="flex-col flex-grow p-6 relative" onClick={() => { onActionClick() }}>
                    <span className="watermark">{getActionType(action)}</span>
                    <span className={'text-xl font-bold overflow-text-1'}>{action.name}</span>
                    {/* action parameters concatenated with commas */}
                    <span className={'text-gray-600 whitespace-pre-line overflow-text-2'} key={`desc_${action.name}`}>
                        {action.description}
                    </span>
                  </div>
                  {!viewMode && (
                    <ConfirmationModalTrigger
                        onTrigger={() => { onActionDelete(action.id) }}
                        title={'Delete Action'}
                        message={'Are you sure you want to delete this action?'}
                        icon={'Delete'}
                        iconClass={'p-6 cursor-pointer'}
                        confirmText={'Delete'}
                    />
                  )}
                </div>
            )}
        </Draggable>
  )
}
