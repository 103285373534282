import { Button, TextField } from '@nike/eds'
import React, { useEffect } from 'react'
import { type Action, ActionType } from 'types'

interface ActionStepProps {
  nextStep: () => void
  previousStep: () => void
  updateAction: (action: Action) => void
  action: Action
  previousStepDisabled: boolean
}

const failureThresholdKey = '_failureThreshold'

export const ActionStep = ({ nextStep, previousStep, updateAction, action, previousStepDisabled }: ActionStepProps) => {
  const handleClickNext = () => {
    nextStep()
  }
  const handleClickPrevious = () => {
    previousStep()
  }

  useEffect(() => {
    if (action.type === ActionType.BULK_API_CALL) {
      updateAction({ ...action, parameters: { [failureThresholdKey]: action.parameters[failureThresholdKey] || '1' } })
    }
  }, [])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    updateAction({ ...action, parameters: { ...action.parameters, [id]: value } })
  }

  return (
    <div className="stepwizard-grid">
        <h2 className="text-2xl font-bold mb-4">{action.name}</h2>
        <div className="form-grid">
          {Object.entries(action.parameters).map(([key, value], index: number) => {
            const label = key === failureThresholdKey ? 'Failure Threshold' : key
            return (<div key={index.toString()} className="m-4 p-6 bg-white rounded shadow md:w-[300px]">
                      <TextField
                          className="w-full"
                          id={key}
                          value={value}
                          onChange={onChange}
                          label={label}
                      />
                  </div>)
          })}
        </div>
        <div className='button-grid'>
            <Button onClick={handleClickPrevious} color="primary" disabled={previousStepDisabled}>Previous</Button>
            <Button onClick={handleClickNext} color="primary">Next</Button>
        </div>
    </div>
  )
}
