import { Button, Select, Tab } from '@nike/eds'
import { useEffect, useState } from 'react'
import { type CustomSelectOption, emptyOauthCredentials, emptyProfile, type OauthCredentials, type Profile } from 'types'

import { CredentialsConfig } from './CredentialsConfig'
import { InstanceCategoriesTab } from './InstanceCategoriesTab'
import { ProfileModal } from './ProfileModal'
import { ProfileTab } from './ProfileTab'
import { type Entry, KeyValueEditor, toEntryArray } from './TagsEditor'

interface ProfileConfigProps {
  profiles: Record<string, Profile>
  onChangeProfile: (name: string, profile: Profile) => void
  onUpdateProfileName: (newName: string, oldName: string, profile: Profile) => void
  onDeleteProfile: (name: string) => void
  isViewMode: boolean
}

const newCredentialsTab = 'New credentials'
const fixedTabs = ['Profile', 'Tags', 'Categories']

export const ProfileConfig = ({ profiles, onChangeProfile, onUpdateProfileName, onDeleteProfile, isViewMode }: ProfileConfigProps) => {
  const [profileNames, setProfileNames] = useState<string[]>([])
  const [localProfileName, setLocalProfileName] = useState<string>('')
  const [localProfile, setLocalProfile] = useState<Profile>()
  const [profileTabs, setProfileTabs] = useState<string[]>([...fixedTabs, newCredentialsTab])

  const [activeProfileTab, setActiveProfileTab] = useState<string>(profileTabs[0])
  const [showModal, setShowModal] = useState(false)

  const [errorCredentials, setErrorCredentials] = useState<Record<string, string>>()
  const [errorTags, setErrorTags] = useState<string>()

  useEffect(() => {
    const instanceProfileNames = Object.keys(profiles)
    setProfileNames(instanceProfileNames)
    if (!instanceProfileNames.includes(localProfileName)) {
      setLocalProfileName('')
      setLocalProfile(undefined)
    }
  }, [profiles])

  useEffect(() => {
    if (localProfile) {
      const tabsBasedOnMode = [
        ...fixedTabs,
        ...Array.from(Object.keys(localProfile.oauthCredentials))
      ]
      if (!isViewMode) tabsBasedOnMode.push(newCredentialsTab)
      setProfileTabs(tabsBasedOnMode)
    }
  }, [localProfile && localProfile.oauthCredentials])

  useEffect(() => {
    if (localProfileName && localProfile) onChangeProfile(localProfileName, localProfile)
  }, [localProfile, localProfileName])

  const updateTags = (entries: Entry[]) => {
    const keys = entries.map(entry => entry.key)
    if (keys.includes('InstanceCategories')) {
      setErrorTags('Cannot have a tag named "InstanceCategories"')
      return
    }
    if (new Set(keys).size !== keys.length) {
      setErrorTags('Duplicate tag names are not allowed')
      return
    }

    setLocalProfile(prevProfile => {
      if (!prevProfile) return prevProfile

      return {
        ...prevProfile,
        tags: Object.fromEntries(entries.map(entry => [entry.key, entry.value]))
      }
    })
  }

  const checkDuplicateName = (oldName: string, newName: string) => {
    if (oldName === newName) return false
    const existingCredentials = profileTabs.some(tab => tab === newName)
    if (existingCredentials) {
      setErrorCredentials(prev => {
        return {
          ...prev,
          credentialsName: 'Credentials name already exists'
        }
      })
      return true
    }
    return false
  }

  const saveConfigCredentials = (oldName: string, newName: string | null, updatedCreds: OauthCredentials) => {
    if (checkDuplicateName(oldName, newName!)) return

    const credentialsName = newName !== null ? newName : oldName

    setLocalProfile(prevProfile => {
      if (!prevProfile) return prevProfile

      const { [oldName]: _, ...otherCredentials } = prevProfile.oauthCredentials

      return {
        ...prevProfile,
        oauthCredentials: {
          ...otherCredentials,
          [credentialsName]: updatedCreds
        }
      }
    })
    setActiveProfileTab(credentialsName)
  }

  const deleteCredentials = (credentialsName: string) => {
    setLocalProfile(prevProfile => {
      if (!prevProfile) return prevProfile

      const { [credentialsName]: _, ...otherCredentials } = prevProfile.oauthCredentials

      return {
        ...prevProfile,
        oauthCredentials: otherCredentials
      }
    })
    setActiveProfileTab('Profile')
  }

  const openProfileModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const addProfileName = (newProfile: string) => {
    onChangeProfile(newProfile, emptyProfile)
    setLocalProfileName(newProfile)
    setLocalProfile(emptyProfile)
  }

  const updateProfileName = (updatedName: string, oldName: string) => {
    onUpdateProfileName(updatedName, oldName, localProfile!)
    setLocalProfileName(updatedName)
  }

  const deleteProfile = (profileName: string) => {
    onDeleteProfile(profileName)
    setLocalProfileName('')
    setLocalProfile(undefined)
  }

  const updateInstanceCategories = (categories: string[]) => {
    const allCategories = categories.join(',')
    setLocalProfile(prevProfile => {
      if (!prevProfile) return prevProfile

      return {
        ...prevProfile,
        tags: {
          ...prevProfile.tags,
          InstanceCategories: allCategories
        }
      }
    })
  }

  const showActiveTab = () => {
    const profileTagsWithoutCategories = localProfile?.tags
      ? Object.entries(localProfile?.tags)
        .filter(([key, value]) => key !== 'InstanceCategories')
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
      : {}

    if (activeProfileTab === 'Profile') {
      return (
        <ProfileTab
          profileName={localProfileName}
          updateProfileName={updateProfileName}
          onDeleteProfile={deleteProfile}
          isViewMode={isViewMode}
        />
      )
    } else if (activeProfileTab === 'Tags') {
      return (
        <KeyValueEditor
          entries={toEntryArray(profileTagsWithoutCategories)}
          updateEntries={updateTags}
          readOnly={isViewMode}
          entryTypeDisplayName='tag'
          entryValueLabel='Value'
          errors={errorTags}
          clearErrors={() => { setErrorTags('') }}
        />
      )
    } else if (activeProfileTab === 'Categories') {
      const categoryTags = localProfile?.tags.InstanceCategories

      return (
        <InstanceCategoriesTab
          categories={categoryTags?.split(',') ?? []}
          updateCategories={updateInstanceCategories}
          readOnly={isViewMode}
        />
      )
    } else {
      let credentials: OauthCredentials | undefined
      let credentialsName = activeProfileTab
      if (activeProfileTab === newCredentialsTab) {
        credentials = emptyOauthCredentials
        credentialsName = ''
      } else {
        credentials = localProfile?.oauthCredentials[activeProfileTab]
      }

      return (
        <CredentialsConfig
          credentialsName={credentialsName}
          credentials={credentials!}
          saveConfigCredentials={saveConfigCredentials}
          deleteCredentials={deleteCredentials}
          errors={errorCredentials}
          setErrors={setErrorCredentials}
          isViewMode={isViewMode} />
      )
    }
  }

  return (
    <>
      <div style={{ width: 'calc(60% - 26px)', display: 'flex', gap: '2rem' }}>
        <div style={{ width: '75%' }}>
          <Select
            id='profileSelector'
            label=''
            value={{ value: localProfileName, label: localProfileName }}
            options={profileNames.map((item) => ({ value: item, label: item }))}
            onChange={(option: CustomSelectOption | null) => {
              if (option?.__isNew__) {
                addProfileName(option.value)
              }
              if (option !== null) {
                setLocalProfileName(option.value)
                setLocalProfile(profiles[option.value])
              }
            }}
          />
        </div>
        {!isViewMode && (
          <Button onClick={openProfileModal}>Add new profile</Button>
        )}
      </div>

      {localProfile && (
        <div className={'w-full'} >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ul className='eds-tab-group' role='tablist'>
              {profileTabs.map((tab, index) => (
                <Tab
                  className={activeProfileTab === tab ? 'active-tab' : 'non-active-tab'}
                  key={tab}
                  id={tab}
                  active={activeProfileTab === tab}
                  onChange={(e) => {
                    if (!fixedTabs.includes(activeProfileTab) && activeProfileTab !== newCredentialsTab && profileTabs.includes(activeProfileTab)) {
                      const credErrors = validateOauthCredentials(localProfile.oauthCredentials[activeProfileTab])
                      if (credErrors !== '') {
                        setErrorCredentials(credErrors)
                        return
                      }
                    }
                    setActiveProfileTab(e.target.id)
                  }}
                >
                  {tab}
                </Tab>
              ))}
            </ul>
          </div>

          <div className='mt-4'>
            {showActiveTab()}
          </div>
        </div>
      )}

      {showModal && !isViewMode && (
        <ProfileModal
          closeModal={closeModal}
          addProfileName={addProfileName}
          profileNames={profileNames}
        />
      )}
    </>
  )
}

const validateOauthCredentials = (credentials: OauthCredentials) => {
  const credentialsErrors: Record<string, string> = {}
  if (credentials.authorizationGrantType === 'password') {
    if (credentials.username === '') credentialsErrors.username = 'Username key is required'
    if (credentials.password === '') credentialsErrors.password = 'Password key is required'
  }
  if (credentials.clientSecret === '') credentialsErrors.clientSecret = 'Client secret is required'
  if (credentials.clientId === '') credentialsErrors.clientId = 'Client ID is required'
  if (credentials.tokenUri === '') credentialsErrors.tokenUri = 'Token URI is required'

  return Object.keys(credentialsErrors).length <= 0
    ? ''
    : credentialsErrors
}
