import { DragDropContext, type DropResult } from 'react-beautiful-dnd'
import { type Action } from 'types'

import { ActionItem } from './ActionItem'
import { CustomDroppable } from './CustomDroppable'

interface ActionListProps {
  actions: Action[]
  reorder: (actions: Action[]) => void
  handleActionClick: (action: Action) => void
  onActionDelete: (id: string) => void
  viewMode: boolean
}
export const ActionList = ({ actions, reorder, handleActionClick, viewMode, onActionDelete }: ActionListProps) => {
  const onDragEnd = (result: DropResult) => {
    if (result.destination == null) return
    const reorderedActions = Array.from(actions)
    const [removed] = reorderedActions.splice(result.source.index, 1)
    reorderedActions.splice(result.destination.index, 0, removed)
    reorder(reorderedActions)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <CustomDroppable isDropDisabled={ viewMode } droppableId="actions">
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {actions.map((action, index) => (
                        <div key={`actionItem_${action.name}`}>
                            <ActionItem key={action.name}
                                        action={action}
                                        index={index}
                                        onActionDelete={onActionDelete}
                                        onActionClick={() => { handleActionClick(action) }}
                                        viewMode={viewMode}
                            />
                        </div>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </CustomDroppable>
    </DragDropContext>
  )
}
