import { type ReportHandler } from 'web-vitals'

// Can be used to send Web Vitals performance metrics to Google Analytics, etc.
// Was included in the original create-react-app template.
// https://create-react-app.dev/docs/measuring-performance/#:~:text=reportWebVitals
const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if ((onPerfEntry != null) && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry)
        getFID(onPerfEntry)
        getFCP(onPerfEntry)
        getLCP(onPerfEntry)
        getTTFB(onPerfEntry)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export default reportWebVitals
