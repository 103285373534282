
interface ParameterDisplayProps {
  title: string
  subtext: string
}

export const ParameterDisplay = ({ title, subtext }: ParameterDisplayProps) => (
    <div className="w-1/4 p-2 m-2 border border-gray-300 rounded-md">
        <div className="font-bold mb-2">
            {title}
        </div>
        <div className="text-sm text-gray-700" style={{ whiteSpace: 'pre-line' }}>
             {subtext}
        </div>
    </div>
)
