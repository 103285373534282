import { Button } from '@nike/eds'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, type RootState } from 'redux/store'

const userSelector = (state: RootState) => state.user

export const Unauthorized = () => {
  const navigate = useNavigate()
  const { user } = useAppSelector(userSelector)

  return (
    <>
      <div className="md:w-1/2 w-full m-auto mt-2">
        <div className="flex items-center place-content-between mb-4">
          <h1 className="pt-5 eds-type--title-1">
            403 Unauthorized
          </h1>
        </div>
        <div style={{ marginTop: '2rem', height: 'fit-content' }} className="w-full bg-white eds-elevation--2">
          <div className="p-4">
            { user && user.hasAnyRole()
              ? (
                <>
                  <p className={'text-lg mb-6 whitespace-pre-wrap'}>
                    You&lsquo;re not authorized to access this page.
                  </p>
                  <Button onClick={() => { navigate('/') }} >Back to homepage</Button>
                </>
                )
              : (
                <>
                  <p className={'text-lg mb-6 whitespace-pre-wrap'}>
                    You&lsquo;re not authorized to access this application.
                  </p>
                </>
                )}
          </div>
        </div>
      </div>
    </>
  )
}
