import { type AxiosError } from 'axios'
import { mainSplitApi } from 'redux/store/mainSplitApi'
import { ActionType, type ExecutionDetail, type WorkflowExecution, type WorkflowExecutionDTO } from 'types'
import { calculateDuration, formatUnixTimestamp } from 'utils/DateTools'
import { appendCsv } from 'utils/FileHelper'
import { httpRequest } from 'utils/HttpRequest'
import { InitiateSSE } from 'utils/InitiateSSE'

export const executionApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getExecutions: builder.query<WorkflowExecutionDTO[], void>({
      queryFn () { return { data: [] } },
      async onCacheEntryAdded (arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const abortCtrl = new AbortController() // Create an abort controller for the SSE connection
        try {
          const handleEvent = (data: WorkflowExecutionDTO[]) => {
            updateCachedData((draft) => {
              return data.map(execution => ({
                ...execution,
                startTimeFormatted: formatUnixTimestamp(execution.startTime),
                endTimeFormatted: formatUnixTimestamp(execution.endTime),
                duration: calculateDuration(execution.startTime, execution.endTime)
              }))
            })
          }
          InitiateSSE(handleEvent, abortCtrl, 'executions')
          await cacheDataLoaded
        } catch {
          console.log('Unexpected failure')
        }
        await cacheEntryRemoved
        abortCtrl.abort()
      }
    }),
    submitExecution: builder.mutation<any, { execution: WorkflowExecution, instanceId: string, selectedProfile: string, ticketNumber: string }>({
      queryFn: async ({ execution, instanceId, selectedProfile, ticketNumber }) => {
        if (mustUploadFile(execution)) {
          await uploadFile(execution)
        }
        // Start the execution
        try {
          const body = createRequestBody(execution, instanceId, selectedProfile, ticketNumber)
          const response = await httpRequest.post('/executions', body)
          return { data: response.data }
        } catch (error) {
          const errorMessage = (error as AxiosError).response?.data
          throw new Error(errorMessage as string)
        }
      }
    }),
    retryExecution: builder.mutation<any, { executionId: string }>({
      query: ({ executionId }) => {
        return {
          url: `/executions/${executionId}/retry`,
          method: 'POST'
        }
      }
    }),
    getExecutionDetail: builder.query<ExecutionDetail, { executionId: string }>({
      query: ({ executionId }) => {
        return {
          url: `/executions/${executionId}/detail`,
          method: 'GET'
        }
      }
    }),
    cancelExecution: builder.mutation<any, { executionId: string }>({
      query: ({ executionId }) => {
        return {
          url: `/executions/${executionId}/cancel`,
          method: 'POST'
        }
      }
    })
  }),
  overrideExisting: false
})

const mustUploadFile = (execution: WorkflowExecution): boolean => execution.parameterFile != null

const uploadFile = async (execution: WorkflowExecution) => {
  try {
    const payloadFile = appendCsv(execution.payloadFile)
    const presignedUrlData = await httpRequest.get(`/executions/parameters/upload?workflowId=${execution.workflowId}&id=${execution.id}&fileName=${payloadFile}`)
    const presignedUrl = presignedUrlData.data as string
    await httpRequest.put(presignedUrl, execution.parameterFile, { headers: { 'Content-Type': execution.parameterFile!.type } })
  } catch (error) {
    throw new Error(`Error uploading file: ${(error as Error).message}`)
  }
}

const createRequestBody = (execution: WorkflowExecution, instanceId: string, selectedProfile: string, ticketNumber: string) => {
  const parameters = execution.actions.map((action) => {
    const actionParameters: Record<string, any> = {}

    Object.entries(action.parameters).forEach(([parameterKey, parameterValue]) => {
      if (!parameterKey.startsWith('_')) {
        actionParameters[parameterKey] = parameterValue
      }
    })

    actionParameters._instanceId = instanceId
    actionParameters._instanceProfile = selectedProfile
    if (action.type === ActionType.BULK_API_CALL) {
      actionParameters._delimiter = execution.delimiter
      actionParameters._failureThreshold = action.parameters._failureThreshold
    }
    return {
      name: action.name,
      parameters: actionParameters
    }
  })

  if (execution.globalParameters) {
    parameters.push({ name: '_global', parameters: execution.globalParameters })
  }

  return {
    id: execution.id,
    workflowId: execution.workflowId,
    parameters,
    ticketNumber
  }
}

export const { useGetExecutionsQuery, useSubmitExecutionMutation, useRetryExecutionMutation, useGetExecutionDetailQuery, useCancelExecutionMutation } = executionApi
