
export const NasIcon = (
  <svg
    className="eds-icon"
    width="25"
    height="25"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M63.9999542,8.8992481c0-2.7851996-0.7939987-4.9892998-2.3593979-6.5497999 C55.3563538-3.9113514,38.8339539,2.7459486,24.019455,17.5105476c-0.5382996,0.5366001-1.0302982,1.1086006-1.5033989,1.6948013 c-0.1553001-0.0642014-0.3339996-0.1216011-0.5394993-0.1694012c-4.2666016-0.9970989-17.960001,1.3291016-21.9463005,17.2549 c-0.1025887,0.4081993,0.0615,0.8358994,0.4101,1.0713005c0.169,0.1152,0.3643113,0.1717987,0.5596,0.1717987 c0.206,0,0.4121-0.0634003,0.5869-0.1903992c5.4105997-3.9248009,9.7511997-5.5033989,13.5403004-4.9222984 c-0.2614889,0.4740982-0.5269003,0.9365005-0.8010006,1.3783989c-0.2451992,0.3955002-0.1855993,0.9071999,0.1435118,1.235302 l1.569088,1.5639992l-2.9371996,2.9263c-0.1884995,0.1875-0.2939997,0.4422989-0.2939997,0.7080002
      c0,0.2655983,0.1055002,0.5205002,0.2939997,0.7080002l9.8291121,9.7967987 c0.1952877,0.1944008,0.4500885,0.2919998,0.7059994,0.2919998c0.255888,0,0.510788-0.097599,0.706089-0.2919998 l2.9410992-2.9315987l1.5734997,1.5683975c0.1934013,0.1923027,0.4483128,0.2919998,0.7061005,0.2919998 c0.1805992,0,0.3623009-0.0489006,0.5244007-0.1484985c0.4132996-0.2549019,0.8422985-0.5024986,1.283699-0.7461014 c0.6509018,3.8078995-0.9258003,8.1761017-4.913599,13.6377029c-0.2480011,0.3398972-0.2567997,0.7987976-0.022501,1.1474991 c0.1884995,0.2812004,0.5020008,0.4423981,0.830101,0.4423981c0.0801105,0,0.1611118-0.0098,0.2411995-0.0293007 c15.9404984-3.9638977,18.2959137-17.6093979,17.3134995-21.8652992c-0.0601006-0.2597008-0.1386986-0.4641991-0.2238998-0.6455002
      c0.635498-0.5021973,1.2546997-1.0267982,1.833313-1.6035004C57.1034546,29.2185478,63.9999542,17.0662479,63.9999542,8.8992481z M16.0575562,30.5486488c-3.9013996-0.8223-8.1884995,0.3213005-13.2792997,3.5625 c2.5039001-6.9482002,7.0644999-10.2305012,10.6426001-11.7763996c2.6826-1.1582012,5.1239986-1.4824009,6.6864986-1.4824009 c0.4190006,0,0.7734013,0.0233994,1.0537014,0.0596008L16.0575562,30.5486488z M23.6366673,48.6179466l-8.4121113-8.384697 l2.2304993-2.2217026l7.2674999,7.2423019l1.1445999,1.1404991L23.6366673,48.6179466z M41.5331535,50.5730476 c-1.5468979,3.5830002-4.8417969,8.1483994-11.8368988,10.6514015c3.2461014-5.0694008,4.3935013-9.3389015,3.5703011-13.2294998l9.6737976-5.089901C43.1141548,44.2195473,42.9872551,47.203949,41.5331535,50.5730476z M34.6893539,44.8650475
      c-1.7586861,0.8467026-3.4335976,1.6514015-4.970686,2.5438995L16.4364567,34.1716499 c0.7172985-1.2282028,1.3803997-2.5621014,2.052-3.9399014l4.0607986-7.6671009 c0.8499012-1.3155994,1.7915001-2.5506992,2.8822994-3.6380997C38.9843559,5.4187484,54.916954-1.5236515,60.2284546,3.7654486 c1.1758003,1.1718998,1.7714996,2.8993998,1.7714996,5.1337996c0,7.5449009-6.8251991,19.4169998-16.982399,29.5410023 C42.1884537,41.2595482,38.3759537,43.0925484,34.6893539,44.8650475z"
      fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
    />
    <path d="M42.724556,12.8533487c-1.1395988,1.1337996-1.767601,2.6426001-1.767601,4.2480001 c0,1.6064987,0.6280022,3.1152992,1.767601,4.2490997c1.1748009,1.1688995,2.7178001,1.7539005,4.2607002,1.7539005 c1.5429993,0,3.0859985-0.585001,4.2598-1.7539005c1.139698-1.1338005,1.766613-2.642601,1.766613-4.2490997 c0-1.6054001-0.626915-3.1142006-1.766613-4.2480001C48.8974533,10.5154486,45.0741539,10.5154486,42.724556,12.8533487z M49.8349533,19.9324493c-1.5722847,1.5643997-4.1279984,1.5643997-5.7001991,0 c-0.7588005-0.7559013-1.1777992-1.7608013-1.1777992-2.8311005c0-1.0692997,0.4189987-2.0742006,1.1777992-2.8301001 c0.7861023-0.7821999,1.8183022-1.1728001,2.850502-1.1728001c1.032299,0,2.0634995,0.3906002,2.8496971,1.1728001
      c0.7587128,0.7558994,1.1767159,1.7608004,1.1767159,2.8301001C51.0116692,18.171648,50.5936661,19.176548,49.8349533,19.9324493z"
      fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
    />
    <path d="M16.464756,47.1472473c-0.3896008-0.3915977-1.0223999-0.3915977-1.4139996-0.0018997l-9.9950886,9.9619026 c-0.3907118,0.3895988-0.3926115,1.0223999-0.0020003,1.4139977c0.1952887,0.1963005,0.4511886,0.2940025,0.7080002,0.2940025 c0.2548885,0,0.5107884-0.097702,0.7060885-0.2920036l9.9951-9.9618988 C16.8534565,48.1716499,16.8554554,47.5388489,16.464756,47.1472473z"
      fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
    />
    <path d="M5.7245564,51.9734497c0.2549,0,0.5106997-0.097702,0.7061114-0.2919998l6.5819998-6.5605011 c0.3905888-0.3897018,0.3915882-1.0224991,0.0018883-1.4141006c-0.3895998-0.3915977-1.0223999-0.392601-1.4139996-0.0019989 l-6.5820999,6.5606003c-0.3906002,0.3895988-0.3916001,1.0224991-0.0019002,1.4141006 C5.2118564,51.8757477,5.4677563,51.9734497,5.7245564,51.9734497z"
      fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
    />
    <path d="M18.5018559,50.5837479l-6.5819998,6.5606003c-0.3906002,0.3895988-0.3915997,1.0224991-0.0018997,1.4141006 c0.1953001,0.1962013,0.4510994,0.2938995,0.7080002,0.2938995c0.2547998,0,0.5107107-0.0976982,0.7059994-0.2919998 l6.5821009-6.5605011c0.3906116-0.389698,0.3916111-1.0224991,0.0018997-1.4141006 C19.5263557,50.194149,18.8934555,50.1931496,18.5018559,50.5837479z"
      fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
    />
  </svg>
)
