import { Table, TableCell, TableHeading } from '@nike/eds'
import React from 'react'

interface TableProps {
  headers: string[]
  rows: string [][]
}
export const TableView = ({ headers, rows }: TableProps) => {
  const renderCell = (value: string) => {
    return (
      <TableCell>{value}</TableCell>
    )
  }

  const renderHeaders = (headers: string[]) => {
    return (
      <>
        {headers.length > 0 && <thead>
          <tr>
            {headers.map((header, index) => (
              <TableHeading key={`th-${index}`}><b> {header} </b></TableHeading>
            ))}
          </tr>
        </thead>
        }
      </>
    )
  }

  const renderTable = (headers: string[], rows: string [][]) => {
    return (
      <>
        {rows.length > 0 && <Table>
          { renderHeaders(headers) }
            <tbody>
              {rows.map((row, i) => (
                <tr key={`tr-${i}`}>
                  {
                    row.map(value => renderCell(value))
                  }
                </tr>
              ))}
            </tbody>
        </Table>
        }
      </>
    )
  }

  return (
    <>
      { renderTable(headers, rows) }
      { rows.length < 1 && <b>No data present</b> }
    </>
  )
}
