import { type NavSubItem } from '@nike/eds'
import { CustomWorkflowIcon } from 'components/icon'

export interface CustomNavItem {
  id: string
  paths: string[]
  icon: string | React.ReactElement
  label: string
  active: boolean
  subItems?: NavSubItem[]
  meta?: Record<string, never>
}

export const executionItem = {
  id: 'active',
  paths: ['active', 'execution'],
  icon: 'Play',
  label: 'Executions',
  active: false
}

export const workflowItem = {
  id: 'workflows',
  paths: ['workflows', 'workflow-editor'],
  icon: CustomWorkflowIcon,
  label: 'Workflows',
  active: false
}

export const historyItem = {
  id: 'history',
  paths: ['history'],
  icon: 'History',
  label: 'History',
  active: false
}

export const instanceConfigItem = {
  id: 'instance-config',
  paths: ['instance-config'],
  icon: 'Global',
  label: 'Instance configuration',
  active: false
}

export const adminItem = {
  id: 'admin',
  paths: ['admin'],
  icon: 'WorkoutEquipment',
  label: 'Admin tools',
  active: false
}
