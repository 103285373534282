import { mainSplitApi } from 'redux/store/mainSplitApi'

const roleApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserRoles: builder.query<string[], void>({
      query: () => '/roles'
    })
  })
})

export const { useGetUserRolesQuery } = roleApi
