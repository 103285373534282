
import { LoginCallback } from '@okta/okta-react'
import { AuthRole } from 'auth/const'
import ProtectedRoute from 'auth/ProtectedRoute'
import { Layout } from 'components/layout'
import { AdminView } from 'pages/admin'
import { AuditView } from 'pages/audit'
import { InternalServerError, Unauthorized } from 'pages/error-pages'
import { ExecutionDetailView } from 'pages/execution-detail'
import { ExecutionOverview } from 'pages/execution-overview'
import { ExecutionVisualisation } from 'pages/execution-visualisation'
import { InstanceConfig } from 'pages/instance-config'
import { NewExecution } from 'pages/new-execution'
import { WorkflowView } from 'pages/workflow'
import { WorkflowEditor } from 'pages/workflow-editor'
import { Navigate, Routes as ReactRoutes, Route } from 'react-router-dom'

export const Routes = () => (
  <>
    <ReactRoutes>
      <Route path='/login/callback' element={<LoginCallback />} />

      <Route path='/unauthorized' element={
        <Layout>
          <Unauthorized />
        </Layout>
      } />

      <Route path='/ise' element={
        <Layout>
          <InternalServerError />
        </Layout>
      } />

      <Route element={<ProtectedRoute role={AuthRole.READ} />}>
        <Route path='/' element={<Navigate to='/active' />} />
        <Route path='/active' element={
          <Layout>
            <ExecutionOverview />
          </Layout>
        } />

        <Route path='/execution/:executionId' element={
          <Layout>
            <ExecutionVisualisation />
          </Layout>
        } />
        <Route path='/execution/:executionId/detail' element={
          <Layout>
                <ExecutionDetailView />
            </Layout>
        } />

        <Route path='/workflows' element={
          <Layout>
            <WorkflowView />
          </Layout>
        } />
        <Route path='/workflow-editor' element={
          <Layout>
            <WorkflowEditor />
          </Layout>
        } />
        <Route path='/workflow-editor/:workflowId' element={
          <Layout>
            <WorkflowEditor />
          </Layout>
        } />

        <Route path='/history' element={
          <Layout>
              <AuditView />
          </Layout>
        } />
        <Route path='/instance-config' element={
            <Layout>
                <InstanceConfig />
            </Layout>
        } />
      </Route>

      <Route element={<ProtectedRoute role={AuthRole.WORKFLOW_WRITE} />}>
        <Route path='/workflow-editor/:workflowId/clone' element={
          <Layout>
            <WorkflowEditor />
          </Layout>
        } />
      </Route>

      <Route element={<ProtectedRoute role={AuthRole.WORKFLOW_EXECUTION} />}>
        <Route path='/workflow-execution/:workflowId' element={
          <Layout>
            <NewExecution />
          </Layout>
        } />
      </Route>

      <Route element={<ProtectedRoute role={AuthRole.SUPPORT} />}>

        <Route path='/admin' element={
          <Layout>
            <AdminView />
          </Layout>
        } />

      </Route>

    </ReactRoutes>
  </>
)
