import ReactJson from 'react-json-view'

interface Props {
  value: string
}

export const JsonViewer = ({ value }: Props) => {
  const isValidJSON = (value: any) => {
    if (typeof value !== 'string') return false

    try {
      if (typeof JSON.parse(value) === 'object') {
        return true
      }
    } catch (error) { }
    return false
  }

  return (
    <div className={'mt-4'}>
      {isValidJSON(value)
        ? (
            <ReactJson src={JSON.parse(value)} name={false} collapsed={true} displayDataTypes={false} enableClipboard={true} />
          )
        : (<> {value} </>)}
    </div>
  )
}
