import { Button, Link } from '@nike/eds'
import { useCancelExecutionMutation, useRetryExecutionMutation } from 'api/execution'
import { hasRole } from 'auth/authentication'
import { AuthRole } from 'auth/const'
import { ProgressBar } from 'components/progressbar'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { type RootState, dispatch, useAppSelector } from 'redux/store'
import { ActionStatus, WorkflowStatus, type WorkflowExecutionDTO } from 'types'

import { ConfirmationModal } from './ConfirmationModal'
import './execution.css'

const userSelector = (state: RootState) => state.user

export const ExecutionVisualisationHeader = (execution: WorkflowExecutionDTO) => {
  const navigate = useNavigate()
  const { user } = useAppSelector(userSelector)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showRetryModal, setShowRetryModal] = useState(false)
  const [retryExecution, { isSuccess: isRetrySuccess }] = useRetryExecutionMutation()
  const [cancelExecution, { isSuccess: isCancelSuccess }] = useCancelExecutionMutation()
  const completedActions = execution.actionExecutions.filter(
    (action) => action.status === ActionStatus.COMPLETED
  ).length
  const totalActions = execution.actionExecutions.length
  const progressPercent = (completedActions / totalActions) * 100

  const showDetails = () => {
    navigate(`/execution/${execution.id}/detail`)
  }

  useEffect(() => {
    if (isRetrySuccess) {
      dispatch(showSnackbar('Successfully retried execution', 'success'))
      navigate('/active')
    }
  }, [isRetrySuccess])

  useEffect(() => {
    if (isCancelSuccess) {
      dispatch(showSnackbar('Successfully cancelled execution', 'success'))
      navigate('/active')
    }
  }, [isCancelSuccess])

  return (
    <div className="flex-grow m-4">
      <div className="execution-header flex flex-row justify-between gap-2">
        <div className="flex-grow">
          <span className="font-bold">{execution.workflowName}</span> - <span>{execution.actionExecutions[0].parameters._instanceProfile}@{execution.actionExecutions[0].parameters._instanceName}</span><br/>
        </div>
        <div className='flex flex-row gap-2'>
          {execution.status === WorkflowStatus.IN_PROGRESS && hasRole(AuthRole.WORKFLOW_EXECUTION, user) &&
            <Button className="ml-4 float-right" variant="primary" onClick={() => { setShowCancelModal(true) }}>Cancel</Button>
          }
          {execution.status !== WorkflowStatus.IN_PROGRESS &&
            <>
              {hasRole(AuthRole.WORKFLOW_EXECUTION, user) &&
                <div className="retryBttnWrapper">
                  <Button className="ml-4 float-right" variant="primary" onClick={() => { setShowRetryModal(true) }}>Retry</Button>
                </div>
              }
              <div className="detailsBttnWrapper">
                <Button className="ml-4 float-right" variant="primary" onClick={() => { showDetails() }}>Details</Button>
              </div>
            </>
          }
        </div>
      </div>
      <div className="execution-subheader">
        <span className="font-bold">Ticket: </span>
        <Link href={`https://niketech.service-now.com/incident.do?sysparm_query=number=${execution.ticketNumber}`} target="_blank" rel="noopener noreferrer">{execution.ticketNumber}</Link>
        <br/>
        <span className='initiator'>{execution.initiatorName}</span>
      </div>
      <div className="execution-desc mt-4 mb-4 flex justify-between">
        <div className="text-gray-600 mb-2">{`Start: ${execution.startTimeFormatted}`}</div>
        <div className="text-gray-600 mb-2">{`End: ${execution.endTimeFormatted}`}</div>
        <div className="text-gray-600 mb-2">{(execution.startTime !== -1 && execution.endTime !== -1) && `Duration: ${execution.duration}`}</div>
      </div>
      <ProgressBar value={progressPercent} max={100} />
      <ConfirmationModal isOpen={showCancelModal} closeHandler={setShowCancelModal.bind(null, false)} confirmHandler={cancelExecution.bind(null, { executionId: execution.id })} message="Are you sure to cancel this execution?" />
      <ConfirmationModal isOpen={showRetryModal} closeHandler={setShowRetryModal.bind(null, false)} confirmHandler={retryExecution.bind(null, { executionId: execution.id })} message="Are you sure to retry this execution?" />
    </div>
  )
}
