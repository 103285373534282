import { Select, TextArea, TextField } from '@nike/eds'
import { CategoryChips } from 'components/category-chips'
import { useEffect, useRef, useState } from 'react'
import { type Workflow } from 'types'

import { isUniqueFilename } from '../WorkflowEditor'

export interface WorkflowHeaderProps {
  workflow: Workflow
  updateWorkflow: (newData: Partial<Workflow>) => void
  workflowMode: 'view' | 'new' | 'edit'
  workflowFileNames: string[]
  instanceCategories: string[]
}

export const WorkflowHeader = ({ workflow, updateWorkflow, workflowMode, workflowFileNames, instanceCategories }: WorkflowHeaderProps) => {
  const nameRef = useRef<HTMLInputElement>(null)
  const oldParamaterFile = workflow.payloadFile
  const [payloadFile, setPayloadFile] = useState('')
  const [payloadFileError, setPayloadFileError] = useState('')

  useEffect(() => {
    setPayloadFile(workflow.payloadFile)
  }, [workflow.payloadFile])

  // Autofocus on first field
  useEffect(() => {
    nameRef.current?.focus()
  }, [])

  const handleFilenameChange = (value: string) => {
    if (!isUniqueFilename(value, oldParamaterFile, workflowFileNames)) {
      setPayloadFileError('Filename already exists')
    } else {
      setPayloadFileError('')
    }
    if (value !== oldParamaterFile) {
      updateWorkflow({ payloadFile: value.trim() })
    }
  }

  const addInstanceCategories = (values: string[]) => {
    updateWorkflow({ instanceCategories: values })
  }

  return (
          <>
            {workflowMode !== 'view'
              ? (
                <div className="flex m-4 w-7/8">
                  <div className='w-1/2'>
                      <TextField className={'mb-4'} id={'workflowName'} value={workflow.name} type="text"
                         label={'Workflow name *'} required={true} onChange={(e) => {
                           updateWorkflow({ name: e.target.value })
                         }}
                      ref={nameRef}/>
                      <TextArea className={'mb-4'} id={'descriptionArea'} minRows={3} value={workflow.description}
                        label={'Workflow description *'} required={true} onChange={(e) => {
                          updateWorkflow({ description: e.target.value })
                        }}/>
                      <TextField className="w-full mb-4"
                        id="payloadFileName"
                        value={payloadFile}
                        onChange={(e) => { setPayloadFile(e.target.value) }}
                        onBlur={(e) => { handleFilenameChange(e.target.value) }}
                        label="Parameter file name"
                        hasErrors={payloadFileError !== ''}
                        errorMessage={payloadFileError}
                        afterSlot={<span>.csv</span>}
                        required={false} />
                      <Select
                        id='instanceCategories'
                        label='Instance tags'
                        isMulti
                        value={workflow.instanceCategories && workflow.instanceCategories.length > 0 ? workflow.instanceCategories.map((category) => ({ label: category, value: category })) : []}
                        options={instanceCategories && instanceCategories.map((category) => ({ label: category, value: category }))}
                        onChange={(e) => { addInstanceCategories(e.map(item => item.value)) }}
                      />
                  </div>
                </div>
                )
              : (
                <div className="justify-center items-center m-4 w-7/8">
                  <h1 className="text-2xl font-bold mb-4">{workflow.name}</h1>
                  <p className={'text-lg mb-4 whitespace-pre-wrap'}>{workflow.description}</p>
                  <p className={'text-lg mb-4'}>{workflow.payloadFile}</p>
                  {workflow.instanceCategories && (
                    <div>
                      <CategoryChips
                        categories={workflow.instanceCategories}
                      />
                    </div>
                  )}
                </div>
                )}
        </>
  )
}
