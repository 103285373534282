import { mainSplitApi } from 'redux/store/mainSplitApi'
import { type Instance } from 'types'

export const clearNonPasswordProfileCredentials = (instance: Instance) => {
  const transformedInstance = { ...instance }
  Object.entries(transformedInstance.profiles).forEach(([pkey, profile]) => {
    Object.entries(profile.oauthCredentials).forEach(([ckey, cred]) => {
      if (cred.authorizationGrantType !== 'password') {
        transformedInstance.profiles[pkey].oauthCredentials[ckey].username = ''
        transformedInstance.profiles[pkey].oauthCredentials[ckey].password = ''
      }
    })
  })
  return transformedInstance
}

export const instanceApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInstances: builder.query<Instance[], void>({
      query: () => '/stacks/instances',
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Instance' as const, id })), 'Instance']
          : ['Instance']
    }),
    getInstanceById: builder.query<Instance, string>({
      query: (id) => `/stacks/instances/${id}`,
      providesTags: (result, error, id) => [{ type: 'Instance', id }]
    }),
    getInstanceProfiles: builder.query<Record<string, string[]>, void>({
      query: () => '/stacks/instances/profiles',
      providesTags: ['InstanceProfiles']
    }),
    createInstance: builder.mutation<Instance, Instance>({
      query: (instance) => ({
        url: '/stacks/instances',
        method: 'POST',
        body: instance
      }),
      invalidatesTags: ['Instance', 'InstanceProfiles', 'InstanceCategories']
    }),
    updateInstance: builder.mutation<Instance, Instance>({
      query: (instance) => {
        if (instance.id === null) {
          throw new Error('Instance id is null')
        }
        return {
          url: `/stacks/instances/${instance.id}`,
          method: 'PUT',
          body: instance
        }
      },
      invalidatesTags: (result, error, arg) =>
        [{ type: 'Instance', id: arg.id }, 'InstanceProfiles', 'InstanceCategories']
    }),
    deleteInstance: builder.mutation<void, string>({
      query: (id) => ({
        url: `/stacks/instances/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) =>
        [{ type: 'Instance', id: arg }, 'InstanceProfiles', 'InstanceCategories']
    }),
    getInstancesCategories: builder.query<string[], void>({
      query: () => '/stacks/instances/categories',
      providesTags: ['InstanceCategories']
    })
  })
})

export const {
  useGetAllInstancesQuery,
  useGetInstanceByIdQuery,
  useGetInstanceProfilesQuery,
  useCreateInstanceMutation,
  useUpdateInstanceMutation,
  useDeleteInstanceMutation,
  useGetInstancesCategoriesQuery
} = instanceApi
