import { type NavSubItem } from '@nike/eds'
import { MawmIcon, NasIcon } from 'components/icon'

export interface NavItem {
  id: string
  icon: string | React.ReactElement
  label: string
  active: boolean
  subItems?: NavSubItem[]
  meta?: Record<string, never>
}

export const mawmProject = {
  id: 'mawm',
  icon: MawmIcon,
  label: 'project',
  active: false
}

export const nasProject = {
  id: 'nas',
  icon: NasIcon,
  label: 'project',
  active: false
}
