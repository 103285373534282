import { Button, Spinner } from '@nike/eds'
import { clearNonPasswordProfileCredentials, useCreateInstanceMutation, useDeleteInstanceMutation, useGetInstanceByIdQuery, useUpdateInstanceMutation } from 'api/instance'
import { hasRole } from 'auth/authentication'
import { AuthRole } from 'auth/const'
import { FullScreenOverlay } from 'components/fullscreen-overlay'
import { useEffect, useState } from 'react'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { type RootState, dispatch, useAppSelector } from 'redux/store'
import { type Instance, type Profile, emptyInstanceConfig, type OauthCredentials } from 'types'

import { CustomTextField } from './CustomTextField'
import { ProfileConfig } from './ProfileConfig'

interface InstanceEditorProps {
  instanceId: string
  setSelectedInstanceId: (id: string) => void
  isViewMode: boolean
}

const userSelector = (state: RootState) => state.user

export const InstanceEditor = ({ instanceId, setSelectedInstanceId, isViewMode }: InstanceEditorProps) => {
  const { user } = useAppSelector(userSelector)
  const [instanceConfig, setInstanceConfig] = useState<Instance>(emptyInstanceConfig)
  const [newOrUpdate, setNewOrUpdate] = useState<'new' | 'update'>('new')
  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const { data: instance, isLoading, isError } = useGetInstanceByIdQuery(instanceId, { skip: instanceId === '' || instanceId === 'new' })
  const [createInstance, { isLoading: isCreating, isSuccess: isSuccessCreating }] = useCreateInstanceMutation()
  const [updateInstance, { isLoading: isUpdating, isSuccess: isSuccessUpdating }] = useUpdateInstanceMutation()
  const [deleteInstance, { isLoading: isDeleting, isSuccess: isSuccessDeleting }] = useDeleteInstanceMutation()

  const canDelete = hasRole(AuthRole.SUPPORT, user)

  useEffect(() => {
    setValidationErrors([])
    if (instanceId === '') return
    if (isError) {
      dispatch(showSnackbar('Error loading instance', 'error'))
      return
    }

    if (instanceId === 'new') {
      setNewOrUpdate('new')
      setInstanceConfig(emptyInstanceConfig)
      return
    }

    setNewOrUpdate('update')
    if (instance != null) {
      setInstanceConfig(instance)
    }
  }, [instanceId, instance])

  useEffect(() => {
    if (isSuccessCreating || isSuccessUpdating || isSuccessDeleting) {
      dispatch(showSnackbar('Saved successfully', 'success'))
      setSelectedInstanceId('')
      setValidationErrors([])
    }
  }, [isSuccessCreating, isSuccessUpdating, isSuccessDeleting])

  const onChangeInstance = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationErrors([])
    const { id, value } = e.target
    setInstanceConfig(prevConfig => {
      return {
        ...prevConfig,
        [id]: value
      }
    })
  }

  const onChangeProfile = (name: string, profile: Profile) => {
    setValidationErrors([])
    setInstanceConfig(prevConfig => {
      return {
        ...prevConfig,
        profiles: { ...prevConfig.profiles, [name]: profile }
      }
    })
  }

  const onUpdateProfileName = (newName: string, oldName: string) => {
    setValidationErrors([])
    setInstanceConfig(prevConfig => {
      const { [oldName]: profile, ...otherCredentials } = prevConfig.profiles

      return {
        ...prevConfig,
        profiles: {
          ...otherCredentials,
          [newName]: profile
        }
      }
    })
  }

  const deleteProfile = (name: string) => {
    setValidationErrors([])
    setInstanceConfig(prevConfig => {
      const { [name]: profile, ...otherProfiles } = prevConfig.profiles

      return {
        ...prevConfig,
        profiles: otherProfiles
      }
    })
  }

  const validateInstance = (instance: Instance) => {
    const errors = []
    if (instance.name === '') errors.push('Name is required')

    if (instance.baseUrl === '') {
      errors.push('Base URL is required')
    } else {
      if (!isValidUrl(instance.baseUrl)) errors.push('Base URL is not a valid URL') // Test with backend
    }

    if (Object.keys(instance.profiles).length <= 0) {
      errors.push('At least one profile is required')
    } else {
      for (const profile in instance.profiles) {
        const profileError = validateProfile(profile, instance.profiles[profile])
        if (profileError) errors.push(profileError)
      }
    }

    setValidationErrors(errors)
    return errors.length <= 0
  }

  const saveInstanceConfig = () => {
    const transformedInstance = clearNonPasswordProfileCredentials(instanceConfig)
    if (!validateInstance(transformedInstance)) return
    newOrUpdate === 'new'
      ? createInstance(transformedInstance)
      : updateInstance(transformedInstance)
  }

  return (
    <>
      {
        isLoading
          ? <Spinner size='large' />
          : (
            <div className="w-4/5 bg-white eds-elevation--2">
              <div className="flex flex-wrap gap-8 p-10">
                <div className='w-full flex justify-between'>
                  <h1 className={'eds-type--title-3'}>General</h1>
                  <div className='w-1/3'>
                    {!isViewMode && (
                      <div className='flex gap-2 place-content-end'>
                        {instanceConfig.id && canDelete && (
                          <Button
                            onClick={() => {
                              deleteInstance(instanceConfig.id)
                              setSelectedInstanceId('')
                            }}>
                              Delete instance
                          </Button>
                        )}
                        <Button onClick={() => {
                          saveInstanceConfig()
                        }}>
                          Save instance
                        </Button>
                      </div>
                    )}
                    <p className='text-red-500 ml-2'>{validationErrors.join(', ')}</p>
                  </div>
                </div>
                <CustomTextField
                  id='name'
                  label='Name'
                  width='third'
                  readOnly={isViewMode}
                  value={instanceConfig.name}
                  onChange={onChangeInstance}
                />
                <CustomTextField
                  id='baseUrl'
                  label='Base URL for API calls'
                  width='twothird'
                  readOnly={isViewMode}
                  value={instanceConfig.baseUrl}
                  onChange={onChangeInstance}
                />

                <h1 className={'w-full eds-type--title-3'}>Profile configuration</h1>
                <ProfileConfig
                  profiles={instanceConfig.profiles}
                  onChangeProfile={onChangeProfile}
                  onUpdateProfileName={onUpdateProfileName}
                  onDeleteProfile={deleteProfile}
                  isViewMode={isViewMode} />
              </div>

              {(isCreating || isUpdating || isDeleting) && <FullScreenOverlay />}
            </div>
            )}
    </>
  )
}

const isValidUrl = (url: string) => {
  try {
    const validProtocols = ['http:', 'https:']
    return validProtocols.includes(new URL(url.toLowerCase()).protocol)
  } catch (_) {
    return false
  }
}

const validateProfile = (name: string, profile: Profile) => {
  const profileErrors = []
  if (Object.keys(profile.oauthCredentials).length <= 0) {
    profileErrors.push(`At least one OAuth credential is required for '${name}'`)
  } else {
    for (const credential in profile.oauthCredentials) {
      if (credential === '') profileErrors.push('Unnamed credentials are not allowed')
      const credErrors = validateOauthCredentials(credential, profile.oauthCredentials[credential])
      if (credErrors) profileErrors.push(credErrors)
    }
  }

  return profileErrors.length <= 0
    ? ''
    : `Following errors occured in '${name}' ${profileErrors.join(', ')}`
}

const validateOauthCredentials = (name: string, credentials: OauthCredentials) => {
  const credentialsErrors = []
  if (credentials.authorizationGrantType === 'password') {
    if (credentials.username === '') credentialsErrors.push('Username key')
    if (credentials.password === '') credentialsErrors.push('Password key')
  }
  if (credentials.clientSecret === '') credentialsErrors.push('Client secret key')
  if (credentials.clientId === '') credentialsErrors.push('Client ID key')
  if (credentials.tokenUri === '') credentialsErrors.push('Token URI')

  return credentialsErrors.length <= 0
    ? ''
    : `${credentialsErrors.join(', ')} are required in the credentials of '${name}'`
}
