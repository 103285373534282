import { Icon } from '@nike/eds'
import { Step, Stepper } from 'react-form-stepper'
import { ActionStatus, ActionType, type WorkflowExecutionDTO } from 'types'

import { INVALID_ACTION_ID } from './const'

interface StepperProps {
  execution: WorkflowExecutionDTO
  showActionResult: (actionId: string) => void
}

export const CustomStepper = ({ execution, showActionResult }: StepperProps) => {
  const activeStepsCount = execution.actionExecutions.length - 1

  return (
    <Stepper activeStep={activeStepsCount}>
      {execution.actionExecutions.map((step, index) => {
        let statusIcon
        let label = step.name
        let onClickHandler = () => { showActionResult(step.actionId) }
        switch (step.status) {
          case ActionStatus.COMPLETED:
            statusIcon = <div className="w-9 h-9 bg-green-500 flex items-center justify-center rounded-full"><Icon name="Check" size='l' /></div>
            break
          case ActionStatus.IN_PROGRESS:
            statusIcon = <div className="w-9 h-9 bg-blue-500 flex items-center justify-center rounded-full"><Icon name="Ellipsis" size='m' /></div>
            label += step.type === ActionType.BULK_API_CALL ? ` [OK: ${step.nrSuccesses} - NOK: ${step.nrFailures}]` : ''
            onClickHandler = () => { showActionResult('') }
            break
          case ActionStatus.NO_ITERATIONS:
            statusIcon = <div className="w-9 h-9 bg-gray-300 flex items-center justify-center rounded-full"><Icon name="Null" size="m" /></div>
            break
          case ActionStatus.NOT_STARTED:
            statusIcon = <div className="w-9 h-9 flex bg-gray-300 items-center justify-center rounded-full"><Icon name="Close" size='m' /></div>
            onClickHandler = () => { showActionResult('') }
            break
          case ActionStatus.FAILED:
            statusIcon = <div className="w-9 h-9 bg-red-500 flex items-center justify-center rounded-full"><Icon name="Alert" size='l' /></div>
            break
          case ActionStatus.CANCELLED:
            statusIcon = <div className="w-9 h-9 bg-red-500 flex items-center justify-center rounded-full"><Icon name="Stop" size='l'/></div>
            break
          case ActionStatus.INTERNAL_ERROR:
            statusIcon = <div className="w-9 h-9 bg-orange-300 flex items-center justify-center rounded-full"><Icon name="Alert" size='l' /></div>
            onClickHandler = () => { showActionResult(INVALID_ACTION_ID) }
            break
        }

        return (
          <Step key={index} label={label} onClick={onClickHandler}>
            {statusIcon}
          </Step>
        )
      })}
    </Stepper>
  )
}

export default CustomStepper
