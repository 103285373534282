import React from 'react'

interface ProgressBarProps {
  value: number
  max: number
}

export const ProgressBar = ({ value, max }: ProgressBarProps) => {
  return (
        <div className="w-full bg-gray-200 rounded-full">
            <div
                style={{ width: `${(value / max) * 100}%` }}
                className={'h-2 bg-blue-500 rounded-full'}
            />
        </div>
  )
}

export default ProgressBar
