import { Button, Modal, Select, TextField } from '@nike/eds'
import { Close, PlusCircle, Repeat } from '@nike/nike-design-system-icons'
import { FieldType, type SearchOption } from 'pages/audit/types'
import React, { useEffect, useState } from 'react'
import { type SelectOption } from 'types'
import { formatUnixTimestamp, parseDateString } from 'utils/DateTools'

import './search.css'

interface SearchProps {
  searchContext: Record<string, string>
  searchOptions: SearchOption[]
  updateSearch: (searchMap: Record<string, string>) => void
  refresh: (searchMap: Record<string, string>) => void
}

export const Search = ({ searchContext, searchOptions, updateSearch, refresh }: SearchProps) => {
  const defaultSelectedField = { accessor: '', value: '' }
  const [currentSearchOption, setCurrentSearchOption] = useState<SearchOption>(searchOptions[0])
  const [newSearchContext, setNewSearchContext] = useState<Record<string, string>>({})
  const [showNewFieldQueryModal, setNewFieldQueryModal] = useState(false)
  const [selectedField, setSelectedField] = useState(defaultSelectedField)
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    refresh(searchContext)
  }, [])

  const resetSearchField = (key: string) => {
    const local: Record<string, string> = {}
    local[key] = ''
    setNewSearchContext({ ...newSearchContext, ...local })
    resetFieldFilter()
  }

  const applyUpdatedFilters = () => {
    if (Object.keys(newSearchContext).length > 0) {
      updateSearch({ ...searchContext, ...newSearchContext })
      setNewSearchContext({})
    }
  }

  const handleOpen = () => { setNewFieldQueryModal(true) }

  const handleClose = () => { setNewFieldQueryModal(false) }

  const handleSave = () => {
    if (selectedField.accessor !== '' && selectedField.value !== '') {
      const local: Record<string, string> = {}
      if (isTimeField(selectedField.accessor)) {
        local[selectedField.accessor] = parseDateString(selectedField.value).valueOf().toString()
      } else {
        local[selectedField.accessor] = selectedField.value
      }
      setNewSearchContext({ ...newSearchContext, ...local })
    }
    resetFieldFilter()
    handleClose()
  }

  const resetFieldFilter = () => {
    setSelectedField(defaultSelectedField)
  }

  const onSelectionChanged = (selectOption: SelectOption) => {
    const selectedSearchOption = searchOptions.find(searchOption => searchOption.label === selectOption.label)
    if (selectedSearchOption !== undefined) {
      setSelectedFieldAccessor(selectedSearchOption.accessor)
      setCurrentSearchOption(selectedSearchOption)
    }
  }

  const isTimeField = (ref: string): boolean => {
    return searchOptions.some(value => value.accessor === ref && value.type === FieldType.DATETIME)
  }

  useEffect(() => {
    if (selectOptions.length === 0) {
      const local: SelectOption[] = []
      searchOptions.forEach((so) => {
        local.push({ value: '', label: so.label })
      })
      setSelectOptions(local)
    }
  }, [searchOptions])

  const modalFooter = (
    <div className={'flex place-content-between'}>
      <Button onClick={handleClose}>Close</Button>
      <Button onClick={handleSave}>Save</Button>
    </div>
  )

  const filterUpdates = () => {
    return (
      <>
        {Object.entries(newSearchContext)
          .map(([key, value]) => (
            <Button
              key={key}
              afterSlot={<Close />}
              size="small"
              variant="secondary"
              className={'flex-none'}
              onClick={() => { resetSearchField(key) } }
            >
              {key} : {getFilterValue(key, value)}
            </Button>
          ))}
          {Object.keys(newSearchContext).length > 0
            ? <Button
                size="small"
                variant="primary"
                className={'flex-none'}
                onClick={() => { applyUpdatedFilters() } }
              >Apply</Button>
            : ''}
      </>
    )
  }

  const modalHeader = (<>Add filter</>)

  const setSelectedFieldAccessor = (accessor: string) => {
    setSelectedField({ ...selectedField, accessor })
  }

  const setSelectedFieldValue = (value: string) => {
    setSelectedField({ ...selectedField, value })
  }

  const getFilterValue = (key: string, value: string): string => {
    return (key === 'before' || key === 'after') ? formatUnixTimestamp(Number(value)) : (value === '' ? 'clear' : value)
  }

  const renderSearchFieldValueInput = () => {
    if (currentSearchOption.values.length > 0) {
      return (
        <>
          <Select
            onChange={selected => { selected !== null && setSelectedFieldValue(selected.value) } }
            id={'fieldQueryValue'}
            label={'Value'}
            options={currentSearchOption.values}
            className='mb-4'
            isDisabled={false}/>
        </>
      )
    } else {
      return (
        <TextField
          id={'fieldQueryValue'}
          type={currentSearchOption.type === FieldType.DATETIME ? 'datetime-local' : 'text'}
          label={'Value'}
          onChange={e => { e !== null && setSelectedFieldValue(e.target.value) } }
        />
      )
    }
  }

  return (<>
      <div className="w-full m-auto mt-2">
        <div className={'search-main'}>
            <div className="search-header-grid w-full">
                <h1 className="py-5 eds-type--title-1">
                    Filters:
                </h1>
                <div className={'search-actions'}>
                  <Button
                      className={'h-1/2 place-items-center'}
                      beforeSlot={<PlusCircle />}
                      size="small"
                      variant="primary"
                      onClick={() => { handleOpen() }}
                  >
                      Add/Update filter
                  </Button>
                  <Button
                    className={'h-1/2 place-items-center'}
                    beforeSlot={<Repeat />}
                    size="small"
                    variant="primary"
                    onClick={() => { refresh(searchContext) }}
                  />
                </div>
            </div>
            <div><h2>Active filters:</h2></div>
            <div className={'search-active'}>
                {Object.entries(searchContext)
                  .filter(([key, value]) => value !== '')
                  .map(([key, value]) => (
                    <Button
                        key={key}
                        afterSlot={<Close />}
                        size="small"
                        variant="primary"
                        className={'flex-none'}
                        onClick={() => { resetSearchField(key) } }
                    >
                        {key} : {getFilterValue(key, value)}
                    </Button>
                  ))
                }
            </div>
           {Object.entries(newSearchContext).length > 0
             ? <>
               <div><h3>New filter to apply:</h3></div>
               <div className={'search-update'}>
                 {filterUpdates()}
               </div>
             </>
             : ''}
        </div>
      </div>
      {(showNewFieldQueryModal) &&
          <Modal isOpen={true} onDismiss={handleClose} footerSlot={modalFooter} headerSlot={modalHeader} hideFade={false}>
              <div className={'columns-1 bg-white p-8 rounded'} style={{ width: '35vw' }}>
                  <Select
                      onChange={option => { option !== null && onSelectionChanged(option) } }
                      id={'fieldName'}
                      label={'Field'}
                      options={selectOptions}
                      className='mb-4'
                      minMenuHeight={searchOptions.length}
                      isDisabled={false}
                  />
                {renderSearchFieldValueInput()}
              </div>
          </Modal>
      }
  </>)
}
