import { mainSplitApi } from 'redux/store/mainSplitApi'
import { type AuditEventDTO } from 'types/AuditEvent'

export const auditEventApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAuditEvents: builder.query<AuditEventDTO[], { after?: string, before?: string, userEmail?: string, workflowId?: string, snowTicket?: string, eventType?: string }>({
      query: ({ after, before, userEmail, workflowId, snowTicket, eventType }) => {
        const afterParam = after === '' ? getDateMinus1D() : after
        const params = {
          ...(afterParam != null && afterParam !== '' && { after: afterParam }),
          ...(before != null && before !== '' && { before }),
          ...(userEmail != null && userEmail !== '' && { userEmail }),
          ...(workflowId != null && workflowId !== '' && { workflowId }),
          ...(snowTicket != null && snowTicket !== '' && { snowTicket }),
          ...(eventType != null && eventType !== '' && { eventType })
        }
        return {
          url: '/audit/events',
          params
        }
      }
    })
  }),
  overrideExisting: false
})

const getDateMinus1D = (): string => {
  const date = new Date()
  const oneDayInMillis = 24 * 60 * 60 * 1000
  return (date.getTime() - oneDayInMillis).toString()
}

export const { useGetAuditEventsQuery } = auditEventApi
