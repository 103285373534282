import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type User from 'types/User'

export interface UserState {
  user?: User
}

const initialState: UserState = {
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initializeUser: (state, result: PayloadAction<User>) => {
      state.user = result.payload
    }
  }
})

export const { actions, reducer } = userSlice
