import { Icon } from '@nike/eds'
import { hasRole } from 'auth/authentication'
import { AuthRole } from 'auth/const'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, type RootState } from 'redux/store'
import { type Workflow } from 'types'

interface WorkflowLineProps {
  workflow: Workflow
}

const userSelector = (state: RootState) => state.user

export function WorkflowLine ({ workflow }: WorkflowLineProps) {
  const navigate = useNavigate()
  const { user } = useAppSelector(userSelector)

  return (
    <div className="flex items-center p-4 bg-white shadow rounded mb-4" key={`div_${workflow.id}`}>
      <div
        className="flex flex-col items-start p-4 mb-4 basis-5/6 cursor-pointer"
        onClick={() => { navigate(`/workflow-editor/${workflow.id}`) }}
      >
        <div className="flex-none flex items-start justify-center text-xl font-bold mr-4 overflow-hover-text-1">
          {workflow.name} - v{workflow.version}
        </div>
        <div className="flex-grow">
          <div className="mb-2">
            <span className="float-right overflow-text-2">{workflow.description}</span>
          </div>
        </div>
      </div>
      {hasRole(AuthRole.WORKFLOW_EXECUTION, user) &&
        (<Icon
          className={'ml-8 cursor-pointer'}
          name="Play"
          size="l"
          onClick={() => { navigate(`/workflow-execution/${workflow.id}`) }}
        />)
      }
    </div>
  )
}
