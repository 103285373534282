import { Spinner } from '@nike/eds'
import { useGetExecutionsQuery } from 'api/execution'
import { useGetActionResultQuery } from 'api/result'
import { ParameterDisplay } from 'components/parameter-display'
import { CustomStepper, INVALID_ACTION_ID } from 'pages/execution-visualisation/components/stepper'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ActionStatus, type WorkflowExecutionDTO } from 'types'

import { ExecutionResult, ExecutionVisualisationHeader } from './components'

export const ExecutionVisualisation = () => {
  const { executionId } = useParams()
  const [execution, setExecution] = useState<WorkflowExecutionDTO>()
  const [selectedActionId, setSelectedActionId] = useState<string>('')
  const { data: executions } = useGetExecutionsQuery()
  const actionResultQueryArgs = execution?.workflowId !== undefined && executionId !== undefined && selectedActionId !== undefined
    ? { workflowId: execution.workflowId, executionId, actionId: selectedActionId }
    : { workflowId: '', executionId: '', actionId: '' }

  const { data: actionResult, isLoading: isActionResultLoading, isError } = useGetActionResultQuery(actionResultQueryArgs, { skip: actionResultQueryArgs.actionId === '' || actionResultQueryArgs.actionId === INVALID_ACTION_ID })

  useEffect(() => {
    if (executions !== undefined && executionId !== undefined) {
      const foundExecution = executions.find(ex => ex.id === executionId)
      if (foundExecution !== undefined) {
        setExecution(foundExecution)
      }
    }
  }, [executions, executionId])

  const getParamsToDisplay = (parameters: Record<string, string>) => {
    return Object.entries(parameters)
      .filter(([key, value]) => (!key.startsWith('_')))
  }

  const renderExecutionResult = () => {
    if (isError) {
      return (
        <div className={'flex justify-center m-20'}>
          No results. Workflow failed due to a technical error!
        </div>
      )
    } else if (isActionResultLoading) {
      return (
        <div className={'flex justify-center m-20'}>
          <Spinner size="large" />
        </div>
      )
    } else if (selectedActionId === INVALID_ACTION_ID) {
      return (
        <div className={'flex justify-center m-20'}>
          Action failed due to a technical error!
        </div>
      )
    } else if (actionResult === undefined) {
      return (
        <div className={'flex justify-center m-20'}>
          Please click on an action button for more info
        </div>
      )
    } else if (selectedActionId != null && execution?.actionExecutions.find(action => action.actionId === selectedActionId)?.status === ActionStatus.NO_ITERATIONS) {
      return (
        <div className={'flex justify-center m-20'}>
          No iterations for this action. Input file to action must have had no records.
        </div>)
    } else if (actionResult.actionId !== selectedActionId) {
      return (
        <div className={'flex justify-center m-20'}>
          Result not (yet) available
        </div>
      )
    } else {
      return <ExecutionResult actionResult={actionResult} />
    }
  }

  return (
    <>
      <div className="md:w-1/2 w-full m-auto mt-2">
        <h1 className="pt-5 eds-type--title-1">
          Execution
        </h1>
      </div>
      <div className="flex justify-center items-center">
        <div style={{ marginTop: '6rem' }} className="w-4/5 bg-white eds-elevation--2">
          {execution === undefined
            ? (
              <div className={'flex justify-center m-20'}>
                <Spinner size="large" />
              </div>
              )
            : (<>
              <ExecutionVisualisationHeader {...execution} />
              <div className="flex justify-between m-4">
                {execution?.actionExecutions.map((actionExecution, index) => (
                  Object.entries(getParamsToDisplay(actionExecution.parameters)).length > 0 &&
                  <ParameterDisplay key={index} title={actionExecution.name} subtext={
                    getParamsToDisplay(actionExecution.parameters).map(([key, value]) => `${key}: ${value}`).join('\n')
                  } />
                ))}
              </div>
              <CustomStepper execution={execution} showActionResult={(actionId) => { setSelectedActionId(actionId) }} />
              <br />
              <div>
                {renderExecutionResult()}

                {execution.errorMessage && (
                  <div className={'ml-10'}>
                    <div className="text-red-600 mb-2">{execution.errorMessage}</div>
                  </div>
                )}
              </div>
              <br />
            </>)}
        </div>
      </div>
      <div className={'mt-6 text-gray-400'}>
        workflow ID: {execution?.workflowId}
      </div>
    </>
  )
}
