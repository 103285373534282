import { Select, TextField } from '@nike/eds'
import { InfoWrapper } from 'components/info-wrapper'
import { useState, type ChangeEvent } from 'react'
import { hasExtension, isSmallerThanOrEqual } from 'utils/FileHelper'

interface CSVFileUploaderProps {
  onValid: (file: File, delimiter: string) => void
  onInvalid: () => void
}

interface Delimiter {
  label: string
  value: string
}

const S3_MAX_MEGABYTES = 5000

export const CSVFileUploader = ({ onValid, onInvalid }: CSVFileUploaderProps) => {
  const tooltip = 'File requirements: CSV-file and UTF-8 encoded'
  const delimiters: Delimiter[] = [
    { label: 'Comma', value: ',' },
    { label: 'Semicolon', value: ';' },
    { label: 'Space', value: ' ' },
    { label: 'Pipe', value: '|' }
  ]
  const [delimiter, setDelimiter] = useState(delimiters[0])

  const handleSelect = (selected: any) => {
    setDelimiter(selected)
  }

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null || e.target.files.length === 0) {
      return
    }
    const file = e.target.files[0]
    if (isValidFile(file)) {
      onValid(file, delimiter.value)
    } else {
      onInvalid()
    }
  }
  return (
    <div className="bg-white p-8 rounded">
      <Select
        id="delimiter"
        label="Delimiter"
        className="mb-4"
        options={delimiters}
        defaultValue={delimiters[0]}
        onChange={handleSelect} />
      <InfoWrapper tooltip={tooltip}>
        <TextField
          id="file"
          type="file"
          label=""
          onChange={handleUpload} />
      </InfoWrapper>
    </div>
  )
}

const isValidFile = (file: File): boolean => {
  return hasExtension(file, 'csv') && isSmallerThanOrEqual(file, S3_MAX_MEGABYTES)
}
