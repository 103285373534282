import { Button, TextField } from '@nike/eds'
import React from 'react'

interface ActionStepProps {
  nextStep: () => void
  previousStep: () => void
  updateParameters: (parameters: Record<string, string>) => void
  parameters: Record<string, string>
  previousStepDisabled: boolean
}

export const GlobalParametersStep = ({ nextStep, previousStep, updateParameters, parameters, previousStepDisabled }: ActionStepProps) => {
  const [globalParameters, setGlobalParameters] = React.useState<Record<string, string>>(parameters)

  const handleClickNext = () => {
    nextStep()
  }
  const handleClickPrevious = () => {
    previousStep()
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setGlobalParameters(prevGlobalParameters => { return { ...prevGlobalParameters, [id]: value } })
    updateParameters({ ...globalParameters, [id]: value })
  }

  return (
    <div className="stepwizard-grid">
        <h2 className="text-2xl font-bold mb-4">Global Parameters</h2>
        <div className="form-grid">
          {Object.entries(globalParameters).map(([key, value], index: number) => {
            return (<div key={index.toString()} className="m-4 p-6 bg-white rounded shadow md:w-[300px]">
                      <TextField
                          className="w-full"
                          id={key}
                          value={value}
                          onChange={onChange}
                          label={key}
                      />
                  </div>)
          })}
        </div>
        <div className='button-grid'>
            <Button onClick={handleClickPrevious} color="primary" disabled={previousStepDisabled}>Previous</Button>
            <Button onClick={handleClickNext} color="primary">Next</Button>
        </div>
    </div>
  )
}
