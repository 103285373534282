import { StatusIndicator, Tab, TabGroup, Table, TableCell, TableHeading } from '@nike/eds'
import { JsonViewer } from 'components/json-view'
import { useEffect, useState } from 'react'
import { ActionType, type ActionResult, type ApiCallActionResponse } from 'types'
import './execution.css'

interface Result {
  actionResult: ActionResult
}

export const ExecutionResult = ({ actionResult }: Result) => {
  const [activeTab, setActiveTab] = useState('')
  const showHeaders = (title: string, headers: Record<string, any>) => {
    return (
        <Table>
          <thead>
          <tr>
            <TableHeading>{title}</TableHeading>
            <TableHeading></TableHeading>
          </tr>
          </thead>
          <tbody>
          {Object.entries(headers).map(([key, value]) => (
              <tr key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>
                  <div className='ml-4'>
                    <JsonViewer value={value} />
                  </div>
                </TableCell>
              </tr>
          ))}
          </tbody>
        </Table>
    )
  }

  const showActiveTab = (response: ApiCallActionResponse) => {
    switch (activeTab) {
      case 'response-body': {
        return <JsonViewer value={response.responseBody} />
      }
      case 'request-body': {
        return <JsonViewer value={response.requestBody} />
      }
      case 'response-headers': {
        return response.responseHeaders
          ? showHeaders('Response Headers', response.responseHeaders)
          : <div className={'mt-4 font-bold'}>No response headers</div>
      }
      case 'request-headers': {
        return response.requestHeaders
          ? showHeaders('Request Headers', response.requestHeaders)
          : <div className={'mt-4 font-bold'}>No request headers</div>
      }
      case 'exception-message': {
        return response.exceptionMessage
      }
      case 'request-uri': {
        return response.requestUri
      }
    }
  }

  useEffect(() => {
    actionResult.responses.forEach((r) => {
      setActiveTab(r.exceptionMessage === null || r.exceptionMessage === '' ? 'response-body' : 'exception-message')
    })
  }, [actionResult])

  return (
    <div id={`result-${actionResult.actionName}`} key={`result-${actionResult.actionName}`} className='m-8'>
      <div className="mb-1">
        <span className="execution-result-title">{actionResult.actionName}</span>
      </div>
      <div>
        Successes: {actionResult.nrSuccesses} <br />
        Failures: {actionResult.nrFailures} <br />
        {actionResult.actionType === ActionType[ActionType.BULK_API_CALL]
          ? <p className='mt-4'>This is a bulk api call, more info can be found in the execution details.</p>
          : <>
              {actionResult.responses.map((r, index) => (
                <div key={index}>
                  <StatusIndicator label={r.statusCode.toString()} status={r.statusCode < 300 && r.statusCode !== 0 ? 'success' : 'danger' } />
                    <hr className={'my-4'}/>

                  <TabGroup
                        activeId={activeTab}
                        name={'tab-button-group'}
                        onChange={e => { setActiveTab(e.target.id) }}>
                      <Tab id={'response-body'}>Response body</Tab>
                      <Tab id={'response-headers'}>Response headers</Tab>
                      <Tab id={'request-body'}>Request body</Tab>
                      <Tab id={'request-headers'}>Request headers</Tab>
                      <Tab id={'request-uri'}>Request URI</Tab>
                      <Tab disabled={r.exceptionMessage === null || r.exceptionMessage === ''} id={'exception-message'}>Exception message</Tab>
                  </TabGroup>
                  <hr />
                  <div className='ml-4'>
                    {showActiveTab(r)}
                  </div>
                </div>
              ))}
          </>
        }
      </div>
    </div>
  )
}
