import { type Action, type ExecutionAction } from 'types'

export enum WorkflowStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  INTERNAL_ERROR = 'INTERNAL_ERROR'
}

export interface WorkflowExecutionDTO {
  id: string
  initiatorName: string
  approvedBy: string
  workflowId: string
  workflowName: string
  startTime: number
  startTimeFormatted: string
  endTime: number
  endTimeFormatted: string
  status: WorkflowStatus
  ticketNumber: string
  actionExecutions: ExecutionAction[]
  duration: string
  errorMessage: string
}

export interface WorkflowExecution {
  id: string
  workflowId: string
  name: string
  description: string
  payloadFile: string
  actions: Action[]
  parameterFile: File | null
  globalParameters: Record<string, string>
  delimiter: string
}
