import { SideBar } from '../sidebar'
export function Layout ({ children }: { children: React.ReactNode }) {
  return (
        <div className="flex eds-flex--direction-column h-screen bg-gray-100">
            <SideBar />
            {/* Sidebar is 104 px, ensuring centering */}
            {/* This also needs bg-gray-100 to ensure all children have full background */}
            <div className="bg-gray-100 p-4 ml-[104px] ">
                {children}
            </div>
        </div>
  )
}
