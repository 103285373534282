
import { Modal, Icon, Button } from '@nike/eds'
import React, { useState } from 'react'

interface ConfirmationModalTriggerProps {
  onTrigger: () => void
  title: string
  message: string
  icon: string
  iconClass: string
  confirmText: string
}

export const ConfirmationModalTrigger = ({ onTrigger, title, message, icon, confirmText, iconClass }: ConfirmationModalTriggerProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  const handleTriggerClick = () => {
    setShowConfirmModal(true)
  }

  const confirmTrigger = () => {
    onTrigger()
    setShowConfirmModal(false)
  }

  return (
    <>
      <button onClick={handleTriggerClick} className={iconClass}>
        <Icon
          name={icon}
          size="l"
        />
      </button>

      {showConfirmModal && (
        <Modal
          isOpen={showConfirmModal}
          onDismiss={() => { setShowConfirmModal(false) }}
          headerSlot={
            <div className="flex justify-between items-center">
              <h3>{title}</h3>
            </div>
          }
          footerSlot={
            <div className="flex justify-end space-x-4">
              <Button onClick={() => { setShowConfirmModal(false) }}>Cancel</Button>
              <Button onClick={confirmTrigger}>{confirmText}</Button>
            </div>
          }
        >
          <p>{message}</p>
        </Modal>
      )}
    </>
  )
}
