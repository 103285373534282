import { Button, Modal, TextField } from '@nike/eds'
import { useState } from 'react'

interface ProfileModalProps {
  closeModal: () => void
  addProfileName: (newProfile: string) => void
  profileNames: string[]
}

export const ProfileModal = ({ closeModal, addProfileName, profileNames }: ProfileModalProps) => {
  const [profileName, setProfileName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const modalHeader = (
    <div className="flex-grow">
        <span>Add new profile</span> <br />
    </div>
  )

  const updateProfile = () => {
    if (profileNames.includes(profileName)) {
      setErrorMessage('Profile name already exists')
      return
    } else if (profileName.trim().length === 0) {
      setErrorMessage('Profile name cannot be empty')
      return
    }

    addProfileName(profileName)

    closeModal()
  }

  const modalFooter = (
        <div className="relative">
            <div className={'flex justify-between'}>
                <div></div>
                <Button onClick={updateProfile}>Add</Button>
            </div>
        </div>
  )

  return (
    <Modal className={'hidden'} isOpen={true} onDismiss={closeModal} headerSlot={modalHeader} footerSlot={modalFooter} hideFade={true}>
      <div className="flex flex-col p-4 bg-white mb-4">
        <TextField
          id='newProfileName'
          onChange={(e) => {
            setErrorMessage('')
            setProfileName(e.target.value)
          }}
          value={profileName}
          label="Profile name"
          hasErrors={errorMessage !== ''}
          errorMessage={errorMessage} />
      </div>
    </Modal>
  )
}
