import { createSlice } from '@reduxjs/toolkit'

export type SnackbarStatus = 'info' | 'success' | 'warning' | 'error' | 'neutral'
export interface SnackbarState {
  message: string
  status: SnackbarStatus
}

const initialState: SnackbarState = {
  message: '',
  status: 'neutral'
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state.status = action.payload.status
      state.message = action.payload.message
    }
  }
})

export const { actions, reducer } = snackbarSlice
