import { Button, Divider } from '@nike/eds'
import { useClearAllCachesMutation, useClearCacheMutation, useGetCachesQuery } from 'api/admin'
import { ConfirmationModal } from 'pages/execution-visualisation/components/ConfirmationModal'
import React, { useEffect, useState } from 'react'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch } from 'redux/store'

export const AdminView = () => {
  const [showClearCacheModal, setShowClearCacheModal] = useState(false)
  const [showClearAllCachesModal, setShowClearAllCachesModal] = useState(false)
  const [cacheId, setCacheId] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [clearAllCaches, { isSuccess: isClearAllCachesSuccess }] = useClearAllCachesMutation()
  const [clearCache, { isSuccess: isClearCacheSuccess }] = useClearCacheMutation()
  const { data: caches } = useGetCachesQuery()

  const renderContent = () => {
    return <>
      <>
        <div className='mb-8 px-2'>
          <b>Cache eviction</b> <br/>
          <Divider />
        </div>
        <div className='flex flex-col mb-8 px-2'>
          {caches?.map(cacheId => (
            <div key={`cache-evict-${cacheId}`} className='flex flex-row mb-8 px-2'>
              <Button size={'small'} className="ml-4 float-right" variant="primary" onClick={() => {
                triggerClearByIdFlow(cacheId)
              }}>Clear {cacheId} cache</Button>
            </div>
          ))}
          <div className='mb-8 px-2'>
            <Button size={'small'} variant="primary" onClick={() => {
              triggerClearAllFlow()
            }}><b>Clear all caches</b></Button>
          </div>
        </div>

      </>
    </>
  }

  const triggerClearAllFlow = () => {
    setModalMessage('Are you sure you want to clear all Hawkeye caches?')
    setShowClearAllCachesModal(true)
  }

  const triggerClearByIdFlow = (cacheId: string) => {
    setCacheId(cacheId)
    setModalMessage(`Are you sure you want to clear ${cacheId} cache?`)
    setShowClearCacheModal(true)
  }

  const runClearCacheAction = () => {
    clearCache({ cacheId })
    setShowClearCacheModal(false)
  }

  const runClearAllCachesAction = () => {
    clearAllCaches()
    setShowClearAllCachesModal(false)
  }

  useEffect(() => {
    if (isClearAllCachesSuccess) {
      dispatch(showSnackbar('Successfully triggered cache clear for all caches!', 'success'))
    }
  }, [isClearAllCachesSuccess])

  useEffect(() => {
    if (isClearCacheSuccess) {
      dispatch(showSnackbar('Successfully triggered cache clear!', 'success'))
    }
    setCacheId('')
    setModalMessage('')
  }, [isClearCacheSuccess])

  return (
    <div className="w-11/12 m-auto">
      <div className="flex items-center place-content-between mb-4">
        <h1 className="py-5 eds-type--title-1">
        Admin utilities
        </h1>
      </div>
      { renderContent() }
      <ConfirmationModal isOpen={showClearCacheModal} closeHandler={() => { setShowClearCacheModal(false) } } confirmHandler={runClearCacheAction} message={modalMessage} />
      <ConfirmationModal isOpen={showClearAllCachesModal} closeHandler={() => { setShowClearAllCachesModal(false) } } confirmHandler={runClearAllCachesAction} message={modalMessage} />
    </div>
  )
}
