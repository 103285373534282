import { Button, Card, Icon, Spinner, TextField } from '@nike/eds'
import { PlusCircleFilled } from '@nike/nike-design-system-icons'
import { useGetWorkflowsQuery } from 'api/workflow'
import { hasRole } from 'auth/authentication'
import { AuthRole } from 'auth/const'
import { Paging } from 'components/paging'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type RootState, useAppSelector } from 'redux/store'
import { type Workflow } from 'types'

import { WorkflowLine } from './components'

const userSelector = (state: RootState) => state.user

export function WorkflowView () {
  const { data: workflows, isLoading } = useGetWorkflowsQuery()
  const { user } = useAppSelector(userSelector)

  const [filteredWorkflowPage, setFilteredWorkflowPage] = useState<Workflow[]>([])

  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState<number>(0)
  const itemsPerPage = 10
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    const filteredWorkflows = filterWorkflows()
    if (filteredWorkflows !== undefined) {
      const endOffset = itemOffset + itemsPerPage
      setFilteredWorkflowPage(filteredWorkflows.slice(itemOffset, endOffset))
      setPageCount(Math.ceil(filteredWorkflows.length / itemsPerPage))
    }
  }, [workflows, filteredWorkflowPage, searchValue, itemOffset])

  const onChangeSearchValue = (value: string) => {
    setSearchValue(value)
  }

  const filterWorkflows = () => {
    if (workflows !== undefined) {
      if (searchValue.length > 0) {
        return workflows.slice().filter(wf =>
          (wf.name.toLowerCase()).includes(searchValue.toLowerCase()) ||
          (wf.description.toLowerCase()).includes(searchValue.toLowerCase()))
      } else {
        return workflows
      }
    }
  }

  return (
    <>
      <div className="md:w-1/2 w-full m-auto mt-2">
        <div className="flex items-center place-content-between mb-4">
          <h1 className="py-5 eds-type--title-1">
            Workflows
          </h1>
          {hasRole(AuthRole.WORKFLOW_WRITE, user) && (
            <Button
              className={'h-1/2 place-items-center'}
              beforeSlot={<PlusCircleFilled />}
              size="medium"
              variant="primary"
              onClick={() => { navigate('/workflow-editor') }}
            >
              New
            </Button>
          )}
        </div>
        <div className='mb-8'>
          <TextField
            id='workflows'
            value={searchValue}
            onChange={(e) => { onChangeSearchValue(e.target.value) }}
            label=''
            beforeSlot={<Icon name='Search' />}
            afterSlot={<Icon name='Close' onClick={() => { setSearchValue('') }} />}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex-auto">
            {isLoading
              ? (<div className='bg-white flex justify-center py-10 shadow rounded '>
                <Spinner size='large' />
              </div>)
              : filteredWorkflowPage.length > 0
                ? <>
                  {filteredWorkflowPage.map((workflow: Workflow) => {
                    return <WorkflowLine key={`wf_${workflow.id}_${workflow.name}`} workflow={workflow} />
                  })}
                  <div>
                    {workflows !== undefined && (
                      <Paging items={workflows} itemsPerPage={itemsPerPage} pageCount={pageCount} setItemOffset={setItemOffset} />
                    )}
                  </div>
                </>
                : <Card className={'w-full border'}>
                  <span className={'text-center'}>No items to display in this list</span>
                </Card>
            }
          </div>
        </div>
      </div>
    </>
  )
}
