
export const CustomWorkflowIcon = (
  <svg
    className="eds-icon"
    width="25"
    height="25"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 56.186 23.091 H 33.814 c -4.909 0 -8.903 -3.994 -8.903 -8.904 V 8.904 c 0 -4.91 3.994 -8.904 8.903 -8.904 h 22.371 c 4.909 0 8.903 3.994 8.903 8.904 v 5.284 C 65.089 19.097 61.095 23.091 56.186 23.091 z M 33.814 4 c -2.704 0 -4.903 2.2 -4.903 4.904 v 5.284 c 0 2.704 2.2 4.904 4.903 4.904 h 22.371 c 2.704 0 4.903 -2.2 4.903 -4.904 V 8.904 C 61.089 6.2 58.89 4 56.186 4 H 33.814 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 56.186 56.546 H 33.814 c -4.909 0 -8.903 -3.994 -8.903 -8.904 v -5.284 c 0 -4.909 3.994 -8.903 8.903 -8.903 h 22.371 c 4.909 0 8.903 3.994 8.903 8.903 v 5.284 C 65.089 52.552 61.095 56.546 56.186 56.546 z M 33.814 37.455 c -2.704 0 -4.903 2.2 -4.903 4.903 v 5.284 c 0 2.704 2.2 4.904 4.903 4.904 h 22.371 c 2.704 0 4.903 -2.2 4.903 -4.904 v -5.284 c 0 -2.704 -2.199 -4.903 -4.903 -4.903 H 33.814 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 56.186 90 H 33.814 c -4.909 0 -8.903 -3.994 -8.903 -8.903 v -5.284 c 0 -4.909 3.994 -8.903 8.903 -8.903 h 22.371 c 4.909 0 8.903 3.994 8.903 8.903 v 5.284 C 65.089 86.006 61.095 90 56.186 90 z M 33.814 70.909 c -2.704 0 -4.903 2.199 -4.903 4.903 v 5.284 c 0 2.704 2.2 4.903 4.903 4.903 h 22.371 c 2.704 0 4.903 -2.199 4.903 -4.903 v -5.284 c 0 -2.704 -2.199 -4.903 -4.903 -4.903 H 33.814 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 74.71 47 h -6.147 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 6.147 c 2.704 0 4.903 -2.2 4.903 -4.904 V 18.449 c 0 -2.704 -2.199 -4.904 -4.903 -4.904 H 63.089 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 H 74.71 c 4.909 0 8.903 3.994 8.903 8.904 v 19.647 C 83.613 43.006 79.619 47 74.71 47 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 71.712 50.15 c -0.512 0 -1.023 -0.195 -1.414 -0.586 l -3.149 -3.15 c -0.781 -0.781 -0.781 -2.047 0 -2.828 l 3.149 -3.15 c 0.781 -0.781 2.047 -0.781 2.828 0 c 0.781 0.781 0.781 2.047 0 2.829 L 71.391 45 l 1.735 1.736 c 0.781 0.781 0.781 2.048 0 2.828 C 72.735 49.955 72.224 50.15 71.712 50.15 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 21.438 80.454 H 15.29 c -4.909 0 -8.903 -3.994 -8.903 -8.903 V 51.903 c 0 -4.909 3.994 -8.903 8.903 -8.903 h 11.621 c 1.104 0 2 0.896 2 2 s -0.896 2 -2 2 H 15.29 c -2.704 0 -4.903 2.199 -4.903 4.903 v 19.647 c 0 2.704 2.2 4.903 4.903 4.903 h 6.148 c 1.104 0 2 0.896 2 2 S 22.542 80.454 21.438 80.454 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 18.288 83.604 c -0.512 0 -1.024 -0.195 -1.414 -0.586 c -0.781 -0.781 -0.781 -2.047 0 -2.828 l 1.736 -1.736 l -1.736 -1.735 c -0.781 -0.78 -0.781 -2.047 0 -2.828 c 0.781 -0.781 2.047 -0.781 2.829 0 l 3.15 3.149 c 0.375 0.375 0.586 0.884 0.586 1.414 s -0.21 1.039 -0.586 1.414 l -3.15 3.15 C 19.312 83.409 18.799 83.604 18.288 83.604 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 45 23.591 c -1.104 0 -2 -0.896 -2 -2 v -0.5 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 0.5 C 47 22.696 46.104 23.591 45 23.591 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 45 30.718 c -1.104 0 -2 -0.896 -2 -2 v -0.891 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 0.891 C 47 29.823 46.104 30.718 45 30.718 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 45 37.455 c -1.104 0 -2 -0.896 -2 -2 v -0.5 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 0.5 C 47 36.559 46.104 37.455 45 37.455 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 45 57.476 c -1.104 0 -2 -0.896 -2 -2 v -0.5 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 0.5 C 47 56.58 46.104 57.476 45 57.476 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 45 64.373 c -1.104 0 -2 -0.896 -2 -2 v -0.862 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 0.862 C 47 63.478 46.104 64.373 45 64.373 z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 45 70.909 c -1.104 0 -2 -0.896 -2 -2 v -0.5 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 0.5 C 47 70.014 46.104 70.909 45 70.909 z" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
)
