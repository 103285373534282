import { Editor } from '@monaco-editor/react'
import { Button, Modal, Select, TextArea, TextField } from '@nike/eds'
import { parse } from 'acorn'
import { hasRole } from 'auth/authentication'
import { AuthRole } from 'auth/const'
import { useEffect, useRef, useState } from 'react'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch, useAppSelector, type RootState } from 'redux/store'
import { ActionType, type Action, type SelectOption } from 'types'
import { deepEqual } from 'utils/ComparisonTools'

import { WorkflowMode } from '../types'
import { isUniqueFilename } from '../WorkflowEditor'

import { hasDuplicates, OrderedKVEditor, toEntryArray, type Entry } from './OrderedKVEditor'

import './workflow-editor.css'

const userSelector = (state: RootState) => state.user

interface ActionModalProps {
  onClose: () => void
  action: Action
  modalMode: 'new' | 'edit' | undefined
  workflowMode: WorkflowMode
  workflowFileNames: string[]
  updateActions: (action: Action, origActionName?: string) => void
  profileCredentials: Record<string, string[]>
}

export const ActionModal = ({ onClose, action, updateActions, modalMode, workflowMode, workflowFileNames, profileCredentials }: ActionModalProps) => {
  const [localAction, setLocalAction] = useState<Action>(action)
  const [params, setParams] = useState<Entry[]>(toEntryArray(localAction.parameters))
  const [headers, setHeaders] = useState<Entry[]>(toEntryArray(localAction.headers))
  const [errors, setErrors] = useState({ name: '', username: '', url: '', jsBody: '', preRequestScript: '', extractionFile: '', extractionScript: '', inputFile: '' })
  const nameRef = useRef<HTMLInputElement>(null)
  const urlRef = useRef<HTMLInputElement>(null)
  const usernameRef = useRef<HTMLDivElement>(null)
  const preRequestRef = useRef<HTMLDivElement>(null)
  const extractionRef = useRef<HTMLDivElement>(null)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [enableFileInput, setEnableFileInput] = useState<boolean>(false)
  const { user } = useAppSelector(userSelector)
  const oldExtractionFilename = action.extractionFilename

  // Autofocus on first field
  useEffect(() => {
    nameRef.current?.focus()
  }, [])

  const isViewMode = workflowMode === WorkflowMode.VIEW

  const typeOptions: SelectOption[] = [
    { value: ActionType[ActionType.API_CALL], label: 'API' },
    { value: ActionType[ActionType.BULK_API_CALL], label: 'BULK API' }
  ]

  const httpMethodOptions: SelectOption[] = [
    { value: 'GET', label: 'GET' },
    { value: 'PUT', label: 'PUT' },
    { value: 'POST', label: 'POST' },
    { value: 'PATCH', label: 'PATCH' },
    { value: 'DELETE', label: 'DELETE' }
  ]

  useEffect(() => {
    const isBulkApiCall = localAction.type === ActionType.BULK_API_CALL
    setEnableFileInput(isBulkApiCall)
    if (isBulkApiCall) {
      setLocalAction({ ...localAction, inputFile: localAction.inputFile ?? '' })
    }
  }, [localAction.type])

  const uniqueAliases = new Set(Object.values(profileCredentials).flat())
  const instanceAliasOptions: SelectOption[] = [...uniqueAliases].map(alias => ({ value: alias, label: alias })) ?? []
  const inputFileOptions: SelectOption[] = workflowFileNames
    // Prevent users from selecting its own exported file as input
    .filter(file => file !== localAction.extractionFilename)
    .map(file => ({ value: file, label: file }))

  const inputsAreValid = () => {
    const errors = { name: '', url: '', username: '', jsBody: '', preRequestScript: '', extractionFile: '', extractionScript: '', inputFile: '' }
    // Checking bottom to top, so that the user is taken to the first invalid field
    if (localAction.preRequestScript) {
      const errorMessage = getScriptErrors(localAction.preRequestScript)
      if (errorMessage != null) {
        // we add a first 17 lines in codeToValidate, reduce the line number by 17 to match what the user sees
        errors.preRequestScript =
          errorMessage.replace(/(\d+):(\d+)/, function (_: any, lineNumber: number, columnNumber: string) {
            return (lineNumber - 17).toString() + ':' + columnNumber
          })
        preRequestRef.current?.scrollIntoView()
      }
    }
    if (localAction.extractionScript) {
      const errorMessage = getScriptErrors(localAction.extractionScript)
      if (errorMessage != null) {
        // we add a first 17 lines in codeToValidate, reduce the line number by 17 to match what the user sees
        errors.extractionScript =
          errorMessage.replace(/(\d+):(\d+)/, function (_: any, lineNumber: number, columnNumber: string) {
            return (lineNumber - 17).toString() + ':' + columnNumber
          })
        extractionRef.current?.scrollIntoView()
      }
    }
    if (localAction.extractionFilename.trim() !== '' && localAction.extractionScript.trim() === '') {
      errors.extractionScript = 'Extraction script is required if extraction filename is provided'
      extractionRef.current?.scrollIntoView()
    }
    if (localAction.extractionScript && localAction.extractionFilename.trim() === '') {
      errors.extractionFile = 'Extraction filename is required'
      extractionRef.current?.scrollIntoView()
    }
    if (localAction.extractionFilename.trim() === '' && localAction.extractionScript.trim() !== '') {
      errors.extractionFile = 'Extraction filename is required if extraction script is provided'
      extractionRef.current?.scrollIntoView()
    }
    if (localAction.extractionFilename.trim() !== '' && !isUniqueFilename(localAction.extractionFilename, oldExtractionFilename, workflowFileNames)) {
      errors.extractionFile = 'Extraction filename must be unique, validate with workflow\'s input file as well'
      extractionRef.current?.scrollIntoView()
    }
    if (!isActionTypeValid(localAction)) {
      errors.inputFile = 'Input file is required'
      inputFileRef.current?.focus()
    }
    try {
      if (localAction.body !== '') JSON.parse(localAction.body)
    } catch (e: any) {
      errors.jsBody = e.message
      extractionRef.current?.scrollIntoView()
    }
    if (localAction.url === '') {
      errors.url = 'URL is required'
      urlRef.current?.focus()
    }
    if (!localAction.username) {
      errors.username = 'User is required'
      usernameRef.current?.scrollIntoView()
    }
    if (localAction.name === '') {
      errors.name = 'Action name is required'
      nameRef.current?.focus()
    }
    setErrors(errors)
    return Object.values(errors).find(error => error) === undefined
  }

  const currentActionType = () => {
    return typeOptions.filter(typeOption => typeOption.value === ActionType[localAction.type ?? ActionType.API_CALL]).find(typeOption => typeOption)
  }

  const handleSave = () => {
    if (!inputsAreValid()) {
      dispatch(showSnackbar('Please ensure all fields are correctly filled out', 'error'))
      return
    }

    const headersToSave = headers.filter(header => header.key !== '')
    const paramsToSave = params.filter(param => param.key !== '')

    if (hasDuplicates(headersToSave) || hasDuplicates(paramsToSave)) {
      dispatch(showSnackbar('Please ensure all header and parameter names are unique', 'error'))
      return
    }
    updateActions({
      ...localAction,
      headers: headersToSave.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {}),
      parameters: paramsToSave.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {})
    }, modalMode === 'edit' ? action.name : undefined)
  }

  const handleClose = () => {
    if (deepEqual(action, localAction) || window.confirm('Are you sure you want to discard your changes?')) {
      onClose()
    }
  }

  const footerSlot = (
    <div className={'flex place-content-between mb-4'}>
      <Button onClick={handleClose}>Close</Button>
      {hasRole(AuthRole.WORKFLOW_WRITE, user) && !isViewMode && (
        <Button onClick={handleSave}>Save</Button>
      )}
    </div>
  )

  const clearErrorField = (value: string) => {
    setErrors(prevErrors => ({ ...prevErrors, [value]: '' }))
  }

  return (
    <Modal headerSlot={<>{modalMode === 'new' ? 'Add new Action' : 'Edit Action'}</>}
      footerSlot={footerSlot}
      isOpen={true}
      hideFade={true}
      onDismiss={handleClose}
      className='action-modal'>
      <div className="bg-white p-8 rounded">
        <TextField
          value={localAction.name}
          onChange={(e) => {
            setLocalAction({ ...localAction, name: e.target.value })
            if (errors.name !== '') {
              clearErrorField('name')
            }
          }}
          label="Name"
          hasErrors={errors.name !== ''}
          errorMessage={errors.name}
          ref={nameRef}
          id={'localActionName'}
          readOnly={isViewMode}
          className='mb-4' />
        <TextArea id={'localActionDescription'}
          className='mb-4'
          minRows={3}
          value={localAction.description}
          readOnly={isViewMode}
          label={'Description'}
          onChange={(e) => { setLocalAction({ ...localAction, description: e.target.value }) }}
        />
        {/* Putting the reference and scrolling to Username here as this is otherwise too high
        and will be obfuscated by the modal blur. */}
        <div ref={usernameRef} />
        <Select
          onChange={(selected) => {
            if (selected === null) return
            setLocalAction({ ...localAction, type: ActionType[selected.value as keyof typeof ActionType], inputFile: '' })
          }}
          id={'localActionType'} label={'Type'}
          className='mb-4'
          options={typeOptions}
          defaultValue={currentActionType()}
          isDisabled={isViewMode}
        />
        <Select
          onChange={(selected) => {
            selected !== null && setLocalAction({ ...localAction, username: selected.value })
            if (errors.username !== '') {
              clearErrorField('username')
            }
          }}
          id={'localActionUsername'}
          label={'Username'}
          className='mb-4'
          options={instanceAliasOptions}
          defaultValue={instanceAliasOptions[Math.max(instanceAliasOptions.findIndex(({ value }) => value === localAction.username))]}
          isDisabled={isViewMode}
          hasErrors={errors.username !== ''}
          errorMessage={'Username is required'}
        />

        <hr className={'my-4'} />

        <Select
          onChange={(selected) => { selected !== null && setLocalAction({ ...localAction, httpMethod: selected.value }) }}
          id={'localActionMethod'} label={'Method'}
          className='mb-4'
          options={httpMethodOptions}
          defaultValue={httpMethodOptions[Math.max(httpMethodOptions.findIndex(({ value }) => value === localAction.httpMethod), 0)]}
          isDisabled={isViewMode}
        />
        <TextField
          value={localAction.url}
          readOnly={isViewMode}
          onChange={(e) => {
            setLocalAction({ ...localAction, url: e.target.value })
            if (errors.url !== '') {
              clearErrorField('url')
            }
          }}
          label="URL"
          hasErrors={errors.url !== ''}
          errorMessage={errors.url}
          ref={urlRef}
          id={'localActionUrl'} />

        <hr className={'my-4'} />

        <h4 className={'my-4 eds-type--title-4'}>Headers</h4>
        <OrderedKVEditor
          entries={headers}
          setEntries={setHeaders}
          readOnly={isViewMode}
          entryTypeDisplayName='header'
          entryValueLabel='Value' />

        <TextArea
          minRows={5}
          value={localAction.body}
          readOnly={isViewMode}
          onChange={(e) => {
            setLocalAction({ ...localAction, body: e.target.value })
            if (errors.jsBody !== '') {
              clearErrorField('jsBody')
            }
          }}
          label="Body"
          className='mb-4'
          hasErrors={errors.jsBody !== ''}
          errorMessage={errors.jsBody}
          id={'localActionBody'} />

        <h3 className={'my-4 eds-type--title-3'}>Parameters</h3>
        <OrderedKVEditor
          entries={params}
          setEntries={setParams}
          readOnly={isViewMode}
          entryTypeDisplayName='parameter'
          entryValueLabel='Default value' />

        <div ref={inputFileRef} />
        {enableFileInput &&
          <Select
            onChange={(selected) => { selected !== null && setLocalAction({ ...localAction, inputFile: selected.value }) }}
            id={'localActionInputFile'}
            label={'Input file'}
            className='mb-4'
            options={inputFileOptions}
            defaultValue={inputFileOptions.find(file => file.value === localAction.inputFile)}
            isDisabled={isViewMode}
            hasErrors={errors.inputFile !== ''}
            errorMessage={errors.inputFile}
          />
        }

        <div ref={preRequestRef} />
        <hr className={'my-4'} />

        <h3 className={'my-4 eds-type--title-3'}>Pre-request</h3>
        <Editor
          className="border border-slate-400"
          height="200px"
          language="javascript"
          theme="light"
          value={localAction.preRequestScript}
          onChange={(value) => {
            setLocalAction({ ...localAction, preRequestScript: value ?? '' })
            if (errors.preRequestScript !== '') {
              clearErrorField('preRequestScript')
            }
          }}
          options={{
            inlineSuggest: { enabled: true },
            fontSize: 16,
            formatOnType: true,
            autoClosingBrackets: 'always',
            minimap: { scale: 10 },
            readOnly: isViewMode
          }}
        />

        <div ref={extractionRef} />
        <hr className={'my-4'} />

        <h3 className={'my-4 eds-type--title-3'}>Extraction</h3>
        <TextField
          value={localAction.extractionFilename}
          onChange={(e) => {
            setLocalAction({ ...localAction, extractionFilename: e.target.value })
            if (errors.extractionFile !== '') {
              clearErrorField('extractionFile')
            }
          }}
          label="Filename"
          hasErrors={errors.extractionFile !== ''}
          errorMessage={errors.extractionFile}
          className='mb-4'
          afterSlot={<span>.csv</span>}
          readOnly={isViewMode}
          id={'localExtractFilename'} />
        <Editor
          className="border border-slate-400"
          height="200px"
          language="javascript"
          theme="light"
          value={localAction.extractionScript}
          onChange={(value) => {
            setLocalAction({ ...localAction, extractionScript: value ?? '' })
            if (errors.extractionScript !== '') {
              clearErrorField('extractionScript')
            }
            if (value === '' && errors.extractionFile !== '') {
              clearErrorField('extractionFile')
            }
          }}
          options={{
            inlineSuggest: { enabled: true },
            fontSize: 16,
            formatOnType: true,
            autoClosingBrackets: 'always',
            minimap: { scale: 10 },
            readOnly: isViewMode
          }}
        />
        {errors.extractionScript !== '' && <div className="text-red-600">{errors.extractionScript}</div>}
      </div>
    </Modal>
  )
}

export const isActionTypeValid = (action: Action) => {
  return action.type === ActionType.API_CALL ||
    (action.type === ActionType.BULK_API_CALL && (action.inputFile != null && action.inputFile !== ''))
}

export const getScriptErrors = (script: string): string | null => {
  try {
    const codeToValidate: string = `
      var context = { 
      get: function(param) {}, 
      getKeys: function() {} 
      }; 
      var output = { 
      next: function() {}, 
      put: function(key, value) {}, 
      put: function(index, key, value) {}, 
      get: function(key) {}, 
      get: function(index, key) {}, 
      getAll: function() {}, 
      remove: function(key) {}, 
      remove: function(index, key) {}, 
      clear: function() {}, 
      size: function() {} 
      };\n` + script
    parse(codeToValidate, { ecmaVersion: 6 })
    return null
  } catch (error: any) {
    return error.message
  }
}
