import { Button, Modal } from '@nike/eds'
import { hasRole } from 'auth/authentication'
import { AuthRole } from 'auth/const'
import { useState } from 'react'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch, type RootState, useAppSelector } from 'redux/store'
import { deepEqual } from 'utils/ComparisonTools'

import { WorkflowMode } from '../types'

import { type Entry, hasDuplicates, OrderedKVEditor, toEntryArray } from './OrderedKVEditor'

const userSelector = (state: RootState) => state.user

interface GlobalParametersModalProps {
  onClose: () => void
  parameters: Record<string, string>
  workflowMode: WorkflowMode
  updateParameters: (parameters: Record<string, string>) => void
}

export const GlobalParameterModal = ({ onClose, parameters = {}, workflowMode, updateParameters }: GlobalParametersModalProps) => {
  const [localParameters, setLocalParameters] = useState<Entry[]>(toEntryArray(parameters))
  const { user } = useAppSelector(userSelector)

  const isViewMode = workflowMode === WorkflowMode.VIEW

  const handleClose = () => {
    if (
      deepEqual(toEntryArray(parameters), localParameters) ||
      window.confirm('Are you sure you want to discard your changes?')
    ) {
      onClose()
    }
  }

  const handleSave = () => {
    const parametersToSave = localParameters.filter(param => param.key !== '')

    if (hasDuplicates(parametersToSave)) {
      dispatch(showSnackbar('Please ensure all parameter names are unique', 'error'))
      return
    }

    updateParameters(parametersToSave.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {}))
  }

  const footerSlot = (
    <div className='flex place-content-between mb-4'>
      <Button onClick={handleClose}>Close</Button>
      {hasRole(AuthRole.WORKFLOW_WRITE, user) && !isViewMode && (
        <Button onClick={handleSave}>Save</Button>
      )}
    </div>
  )

  return (
    <Modal
      headerSlot={'Update global parameters'}
      footerSlot={footerSlot}
      isOpen={true}
      hideFade={true}
      onDismiss={handleClose}
      className='action-modal'
    >
      <div className="bg-white p-8 rounded">
        <OrderedKVEditor
          entries={localParameters}
          setEntries={setLocalParameters}
          readOnly={isViewMode}
          entryTypeDisplayName='Parameter'
          entryValueLabel='Value' />
        </div>
    </Modal>
  )
}
