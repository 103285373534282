import { Button, Modal } from '@nike/eds'

interface ConfirmationModalProps {
  isOpen: boolean
  confirmHandler: () => any
  closeHandler: () => void
  message: string
}

export const ConfirmationModal = ({ isOpen, confirmHandler, closeHandler, message }: ConfirmationModalProps) => {
  return (
        <Modal
            isOpen={isOpen}
            onDismiss={closeHandler}
            headerSlot={
                <div className="flex justify-between items-center">
                    <h3>Confirmation</h3>
                </div>
            }
            footerSlot={
                <div className="flex justify-end space-x-4">
                    <Button onClick={closeHandler}>Close</Button>
                    <Button onClick={confirmHandler}>Confirm</Button>
                </div>
            }
        >
            <p>{message}</p>
        </Modal>
  )
}
