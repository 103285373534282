export const formatUnixTimestamp = (timestamp: number): string => {
  return timestamp == null || timestamp === -1 ? '❌' : formatDateTime(new Date(timestamp))
}

export const formatDateTime = (date: Date): string => {
  return formatDate(date) + ' ' + date.toLocaleTimeString()
}

const formatDate = (date: Date): string => {
  const now = new Date()
  const yesterday = new Date()
  const dateString = date.toDateString()

  yesterday.setDate(now.getDate() - 1)

  return dateString
}

export const calculateDuration = (startTime: number, endTime: number): string => {
  const startDate = new Date(startTime)
  const endDate = new Date(endTime)
  const duration = endDate.valueOf() - startDate.valueOf()
  return formatMiliseconds(duration)
}

function formatMiliseconds (duration: number) {
  let dateString = ''

  const msInHour = 1000 * 60 * 60
  const hours = Math.trunc(duration / msInHour)
  if (hours > 0) {
    dateString += `${hours}h `
    duration = duration - (hours * msInHour)
  }

  const msInMinute = 1000 * 60
  const minutes = Math.trunc(duration / msInMinute)
  if (minutes > 0) {
    dateString += (`${minutes}m `)
    duration = duration - (minutes * msInMinute)
  }

  const msInSecons = 1000
  const seconds = Math.trunc(duration / msInSecons)
  if (seconds > 0) {
    dateString += (`${seconds}s `)
    duration = duration - (seconds * msInSecons)
  }

  const milliseconds = Math.trunc(duration / 1000)
  if (milliseconds < 1000) {
    dateString += (`${duration}ms`)
  }

  return dateString
}

export const parseDateString = (dateStr: string): Date => {
  const [date, time] = dateStr.split('T')
  const [year, month, day] = date.split('-')
  const [hours, minutes] = time.split(':')
  // month is 0-based, that's why we need dataParts[1] - 1
  return new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes))
}
