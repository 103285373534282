import { Select, Spinner } from '@nike/eds'
import { useGetAllInstancesQuery } from 'api/instance'
import { useEffect, useState } from 'react'
import { type SelectOption } from 'types'

interface InstanceSelectionProps {
  selectedInstanceId: string
  setSelectedInstanceId: (id: string) => void
  isViewMode: boolean
}

export const InstanceSelection = ({ selectedInstanceId, setSelectedInstanceId, isViewMode }: InstanceSelectionProps) => {
  const [instanceOptions, setInstanceOptions] = useState<SelectOption[]>([])
  const { data: instances, isLoading } = useGetAllInstancesQuery()

  const instanceConfigMap = new Map<string, SelectOption>(instanceOptions.map((config) => [config.value, config]))
  const [selectedConfig, setSelectedConfig] = useState<SelectOption | undefined>(undefined)

  useEffect(() => {
    if (instances === undefined) {
      return
    }
    const mappedOptions: SelectOption[] = [
      ...instances.map((item) => ({ value: item.id, label: item.name }))
    ]
    if (!isViewMode) {
      mappedOptions.unshift({ value: 'new', label: 'New...' })
    }
    setInstanceOptions(mappedOptions)
  }, [instances])

  useEffect(() => {
    setSelectedConfig(instanceConfigMap.get(selectedInstanceId))
  }, [selectedInstanceId])

  return (
    isLoading
      ? (
          <div className='bg-white flex justify-center py-10 shadow rounded '>
            <Spinner size='large'/>
          </div>
        )
      : (
          <Select id={'instanceConfig-selector'}
            className={`${selectedInstanceId === '' ? 'translate-x-0' : 'absolute translate-x-full w-[400px]'} transition-transform duration-400 z-50`}
            label={''}
            value={selectedConfig ?? { value: 'select', label: 'Select...' }}
            onChange={(option: SelectOption | null) => {
              setSelectedInstanceId((option?.value) !== undefined ? option.value : '')
            }} options={instanceOptions}
          />
        )
  )
}
