import { TextField, Button } from '@nike/eds'
import { InfoWrapper } from 'components/info-wrapper'
import { useState } from 'react'

export interface Entry {
  key: string
  value: string
}

interface OrderedKVEditorProps {
  entries: Entry[]
  setEntries: (entries: Entry[]) => void
  entryTypeDisplayName: string
  entryValueLabel: string
  readOnly: boolean
}

export const toEntryArray = (data: Record<string, string>): Entry[] => {
  return data && Object.keys(data).length > 0
    ? Object.entries(data).map(([k, v]) => ({ key: k, value: v }))
    : []
}

export const hasDuplicates = (entries: Entry[]): boolean => {
  const entryKeys = entries.map(entry => entry.key)
  return entryKeys.some((key, index) => entryKeys.indexOf(key) !== index)
}

const tooltip = 'Name should start with an alphabetic character (a..z/ A..Z) \n Any other character can be alphabetic, numeric (0-9) or underscore (_)'

export const OrderedKVEditor = ({ entries, setEntries, entryTypeDisplayName, entryValueLabel, readOnly }: OrderedKVEditorProps) => {
  const [error, setError] = useState<string>()

  const handleEntryChange = (index: number, key: string, value: string) => {
    setError(undefined)
    if (!checkKeyIsValid(key)) {
      setError(`${key} is not valid`)
    }

    const newEntries = [...entries]
    newEntries[index] = { key, value }
    setEntries(newEntries)
  }

  const handleAddEntry = () => {
    setEntries([...entries, { key: '', value: '' }])
    // Scroll the modal to the bottom
  }

  const handleRemoveEntry = (index: number) => {
    const newEntries = [...entries]
    newEntries.splice(index, 1)
    setEntries(newEntries)
  }

  const checkKeyIsValid = (key: string) => {
    const velocityTemplateRegex = /^(^[a-zA-Z][a-zA-Z0-9_]*)$/
    return velocityTemplateRegex.test(key)
  }

  return (
      <div>
        {entries.length > 0
          ? (
            <>
              <div className="grid grid-cols-3 gap-4">
                <div>Name</div>
                <div>{entryValueLabel}</div>
              </div>
              {entries.map((entry, index) => (
                  <div key={index} className="grid grid-cols-3 gap-4" style={{ width: '35vw' }}>
                    <TextField
                        value={entry.key}
                        onChange={(e) => { handleEntryChange(index, e.target.value, entry.value) }}
                        readOnly={readOnly}
                        label=""
                        id={`param_${entry.value}`} />
                    <TextField
                        value={entry.value}
                        onChange={(e) => { handleEntryChange(index, entry.key, e.target.value) }}
                        readOnly={readOnly}
                        label=""
                        id={`param_${entry.value}`} />
                    <div className="pt-5">
                      <Button disabled={readOnly} onClick={() => { handleRemoveEntry(index) }}>Remove</Button>
                    </div>
                  </div>
              ))}
            </>
            )
          : (
            <div style={{ width: '35vw' }}></div>
            )}
        <br />
        <div className='mb-4'>
          {error && <div className='flex items-center gap-2'><p className='text-red-500 ml-2'>{error}</p> <InfoWrapper tooltip={tooltip} /> <br /></div>}
          <Button disabled={readOnly} onClick={handleAddEntry}>Add {entryTypeDisplayName}</Button>
        </div>
      </div>
  )
}
