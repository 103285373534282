import { mainSplitApi } from 'redux/store/mainSplitApi'

export const adminApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getCaches: builder.query<string[], void>({
        query: () => '/caches',
        transformResponse: responseData => {
          return (responseData as any[])[0]
        }
      }),
      clearAllCaches: builder.mutation<any, void>({
        query: () => {
          return {
            url: '/caches',
            method: 'DELETE'
          }
        }
      }),
      clearCache: builder.mutation<any, { cacheId: string }>({
        query: ({ cacheId }) => {
          return {
            url: `/caches/${cacheId}`,
            method: 'DELETE'
          }
        }
      })
    })
  },
  overrideExisting: false
})

export const {
  useGetCachesQuery,
  useClearAllCachesMutation,
  useClearCacheMutation
} = adminApi
