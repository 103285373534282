import { TextField } from '@nike/eds'
import React from 'react'

interface CustomTextFieldProps {
  width: string
  id: string
  label: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  hasErrors?: boolean
  errorMessage?: string
  readOnly?: boolean
}

const getWidthClass = (width: string): string => {
  switch (width) {
    case 'third': return 'calc(33% - 30px)'
    case 'twothird': return 'calc(66% - 30px)'
    case 'half': return 'calc(50% - 26px)'
    case 'full': return 'calc(100% - 16px)'
    default: return ''
  }
}

export const CustomTextField: React.FC<CustomTextFieldProps> = ({ width, ...props }) => {
  const customWidth = getWidthClass(width)
  return (
        <div style={{ width: customWidth }}>
            <TextField {...props} />
        </div>
  )
}
