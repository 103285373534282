import { Link, Spinner } from '@nike/eds'
import { useGetExecutionDetailQuery } from 'api/execution'
import { JsonViewer } from 'components/json-view'
import { TableView } from 'components/table'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import { useParams } from 'react-router-dom'
import { type ActionExecutionDetail, type ExecutionDetail, type FileDetailDTO } from 'types'
import { formatUnixTimestamp } from 'utils/DateTools'

export const ExecutionDetailView = () => {
  const { executionId } = useParams()
  const executionDetailQueryArgs = { executionId: executionId ?? '' }
  const { data: executionDetail, isLoading, isError } = useGetExecutionDetailQuery(executionDetailQueryArgs, { skip: executionDetailQueryArgs.executionId === '' })

  const incrementAction = (index: number) => {
    return (index + 1).toString()
  }

  const resolveTimestamp = (timestamp: number | undefined) => {
    if (timestamp === undefined || timestamp === null || timestamp === -1) {
      return '/'
    }
    const timestampAsNumber = +timestamp
    return formatUnixTimestamp(timestampAsNumber)
  }

  const transformEntries = (parameters: Record<string, string>): string[][] => {
    const rows: string [][] = []
    Object.entries(parameters)
      .filter(([key, value]) => (!key.startsWith('_')))
      .forEach(parameter =>
        rows.push(parameter)
      )
    return rows
  }

  const renderActionExecutionParameters = (action: ActionExecutionDetail): React.ReactNode => {
    return <>
      <br />
      <TableView headers={['Name', 'Value']} rows={ transformEntries(action.parameters) } />
    </>
  }

  const renderExecutionDetailAccordeonItem = (executionDetail: ExecutionDetail): React.ReactNode => {
    return (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <b>Execution details</b>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <b>Execution initiator name:</b> {executionDetail.workflowExecutionDetailDto.initiatorName}<br/>
          <b>Execution status:</b> {executionDetail.workflowExecutionDetailDto.status}<br/>
          <b>Execution ticket number:</b> {executionDetail.workflowExecutionDetailDto.ticketNumber}<br/>
          <b>Execution start
            time:</b> {resolveTimestamp(executionDetail.workflowExecutionDetailDto.startTime)}<br/>
          <b>Execution end
            time:</b> {resolveTimestamp(executionDetail.workflowExecutionDetailDto.endTime)}
        </AccordionItemPanel>
      </AccordionItem>
    )
  }

  const renderWorkflowDetailAccordeonItem = (executionDetail: ExecutionDetail): React.ReactNode => {
    return (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <b>Workflow definition</b>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <b>Workflow name:</b> {executionDetail.workflowDetailDto.name}<br/>
          <b>Workflow description:</b> {executionDetail.workflowDetailDto.description}<br/>
          <b>Workflow version:</b> {executionDetail.workflowDetailDto.version}<br/>
          { renderFile(executionDetail.workflowDetailDto.workflowFile, 'Workflow file') }
          { renderFile(executionDetail.workflowDetailDto.payload, 'Payload file') }
        </AccordionItemPanel>
      </AccordionItem>
    )
  }

  const renderActionExecutions = (executionDetail: ExecutionDetail): React.ReactNode => {
    return (
      <>
      {
    executionDetail.actionExecutions.map(
      (action, index) => (
        action !== undefined
          ? <AccordionItem key={action.id}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <b>Action {incrementAction(index)}: { action.name }</b>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
             <b>Action ID:</b> {action.id}<br/>
             <b>Action name:</b> {action.name}<br/>
             <b>Action description:</b> {action.description}<br/>
             <b>Action type:</b> {action.type}<br/>
             <b>Action status:</b> {action.status}<br/>
             <b>Action
               startTime:</b> {resolveTimestamp(action.startTime)}<br/>
             <b>Action endTime:</b> {resolveTimestamp(action.endTime)}<br/>
             <b># Successes:</b> {action.nrSuccesses}<br/>
             <b># Failures:</b> {action.nrFailures}<br/>
             <b>Action parameters:</b>
             {Object.keys(action.parameters).length > 0 ? renderActionExecutionParameters(action) : ' No parameters defined.'}
             <br/>
             { renderFile(action.inputFile, 'Input file:') }
             { renderFile(action.extractionFileDetail, 'Extraction file:') }
             { renderFile(action.resultFileDetail, 'Result file:')}
          </AccordionItemPanel>
        </AccordionItem>
          : <></>
      )
    )
    }
    </>

    )
  }

  const renderFile = (fileDetailDTO: FileDetailDTO, title: string): React.ReactNode => {
    if (fileDetailDTO !== undefined && fileDetailDTO != null) {
      if (fileDetailDTO.fileContentType.toUpperCase() === 'JSON') {
        return renderJsonFile(fileDetailDTO, title)
      }
      if (fileDetailDTO.fileContentType.toUpperCase() === 'CSV') {
        return renderCsvFile(fileDetailDTO, title)
      }
      if (fileDetailDTO.fileContentType.toUpperCase() === 'MIXED') {
        return renderMixedFile(fileDetailDTO, title)
      }
      if (fileDetailDTO.fileContentType.toUpperCase() === 'NONE') {
        return renderNoneFile(fileDetailDTO, title)
      }
    }
    return <></>
  }

  const renderFileName = (fileName: string): React.ReactNode => {
    const cleanFileName = fileName?.length > 0 ? fileName : 'No file name specified.'
    return (
      <>
      Filename: { cleanFileName }
      </>
    )
  }

  const renderMessage = (fileDetailDTO: FileDetailDTO): React.ReactNode => {
    return (
      <>
        Message: { fileDetailDTO.message } &nbsp;
        {fileDetailDTO.fileLink?.length > 0 && <Link href={fileDetailDTO.fileLink}>Download file</Link> }
      </>
    )
  }

  const renderJsonFile = (fileDetailDTO: FileDetailDTO, title: string): React.ReactNode => {
    return (
      <>
        <b>{title}:</b><br/>
        {renderFileName(fileDetailDTO.fileName)} <br/>
        {
          fileDetailDTO.message?.length > 0
            ? renderMessage(fileDetailDTO)
            : <>Content: <JsonViewer value={fileDetailDTO.fileContent}/></>
        } <br/>
      </>
    )
  }

  const renderCsvFile = (fileDetailDTO: FileDetailDTO, title: string): React.ReactNode => {
    return (
      <>
        <b>{title}:</b><br/>
        {renderFileName(fileDetailDTO.fileName)} <br/>
        {
          fileDetailDTO.message?.length > 0
            ? renderMessage(fileDetailDTO)
            : <>Content: { renderCsvAsTable(fileDetailDTO.fileContent) }</>
        } <br/>
      </>
    )
  }

  const renderMixedFile = (fileDetailDTO: FileDetailDTO, title: string): React.ReactNode => {
    return (
      <>
        <b>{title}:</b><br/>
        {renderFileName(fileDetailDTO.fileName)} <br/>
        {
          fileDetailDTO.message?.length > 0
            ? renderMessage(fileDetailDTO)
            : <>Content:<br/> {fileDetailDTO.fileContent}</>
        } <br/>
      </>
    )
  }

  const renderCsvAsTable = (input: string, delim: string = ','): React.ReactNode => {
    const lines = input.split('\n')
    if (lines.length > 0) {
      const firstLine = lines.shift()
      const headers = firstLine !== undefined ? firstLine.split(delim) : []
      const tableRows: string [][] = []
      lines.forEach((line) => {
        tableRows.push(line.split(delim))
      })
      return <TableView headers={headers} rows={tableRows}/>
    } else {
      return <></>
    }
  }

  const renderNoneFile = (fileDetailDTO: FileDetailDTO, title: string): React.ReactNode => {
    return (
      <>
        <b>{title}:</b><br/>
        { renderFileName(fileDetailDTO.fileName) } <br/>
        { renderMessage(fileDetailDTO) } <br/>
      </>
    )
  }

  const renderAccordeon = (executionDetail: ExecutionDetail): React.ReactNode => {
    return (
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        {renderWorkflowDetailAccordeonItem(executionDetail)}
        {renderExecutionDetailAccordeonItem(executionDetail)}
        {renderActionExecutions(executionDetail)}
      </Accordion>
    )
  }

  const renderContent = (executionDetail: ExecutionDetail | undefined): React.ReactNode => {
    if (isLoading) {
      return (
        <div className='bg-white flex justify-center py-10 shadow rounded '>
          <Spinner size='large'/>
        </div>
      )
    } else if (executionDetail !== undefined && !isError) {
      return (
        <>
          <div className='mb-8 px-2'>
            <b>Workflow ID:</b> {executionDetail.workflowDetailDto.id}<br/>
            <b>Execution ID:</b> {executionDetail.workflowExecutionDetailDto.id}<br/>
            <b>Profile and instance:</b> {executionDetail.actionExecutions[0].parameters._instanceProfile}@{executionDetail.actionExecutions[0].parameters._instanceName}<br/>
          </div>
          <div className="flex flex-col">
            { renderAccordeon(executionDetail) }
          </div>
        </>
      )
    } else {
      return (
        <div className='bg-white flex justify-center py-10 shadow rounded '>
          Unable to load execution detail
        </div>
      )
    }
  }

  return (
    <div className="w-11/12 m-auto">
      <div className="flex items-center place-content-between mb-4">
        <h1 className="py-5 eds-type--title-1">
          Execution detail
        </h1>
      </div>
      { renderContent(executionDetail) }
    </div>
  )
}
