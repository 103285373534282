import { type FetchArgs, type FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { type BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { loadToken } from 'utils/OktaToken'

export const customBaseQuery = (baseQueryOptions: FetchBaseQueryArgs) => async (args: FetchArgs | string, api: BaseQueryApi, extraOptions: Record<string, unknown>) => {
  const token = loadToken()
  if (typeof args === 'string') {
    args = { url: args }
  }
  args.url = cleanUrl(args.url) // required to call a root endpoint with a mutation, the '/' is added automatically
  if (token != null) {
    args.headers = { Authorization: `Bearer ${token}` }
  }
  const result = await fetchBaseQuery(baseQueryOptions)(args, api, extraOptions)

  if (result.error != null) {
    const error = result.error
    switch (error.status) {
      case 401:
        window.location.href = '/login'
        break
      case 403:
        api.dispatch(showSnackbar('Forbidden action!', 'error'))
        break
      case 404:
        api.dispatch(showSnackbar(`${result.meta!.request.method} ${args.url} not found!`, 'error'))
        break
      default:
        api.dispatch(showSnackbar(String(error.data), 'error'))
        console.log(error.data)
        break
    }
    return { error: { status: error.status, data: error.data } }
  }

  return result
}

const cleanUrl = (old: string) => {
  return old?.endsWith('/') ? old.substring(0, old.length - 1) : old
}
