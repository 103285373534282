// In order to gain the TypeScript typings (for intellisense / autocomplete) while
// using CommonJS imports with require()
import axios, { type AxiosInstance } from 'axios'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch } from 'redux/store'

import { addTokenToBackendCalls, loadToken } from './OktaToken'

export const httpRequest: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000
})

httpRequest.interceptors.request.use(function (request) {
  const token = loadToken()
  addTokenToBackendCalls(request, token)
  return request
}, async function (error) {
  return await Promise.reject(error)
})

httpRequest.interceptors.response.use(function (response) {
  // Optional: Do something with response data
  return response
}, async function (error) {
  switch (error.response.status) {
    case 401:
      window.location.href = '/login'
      break
    case 403:
      dispatch(showSnackbar('Forbidden action!', 'error'))
      break
    case 500:
      dispatch(showSnackbar(String(error.response.data), 'error'))
      console.log(error.response.data)
      break
    default:
  }

  // Still returning promise.reject so that the caller can handle the error
  return await Promise.reject(error)
})
