import { hasRole } from 'auth/authentication'
import { AuthRole } from 'auth/const'
import { useState } from 'react'
import { useAppSelector, type RootState } from 'redux/store'

import { InstanceEditor } from './components/InstanceEditor'
import { InstanceSelection } from './components/InstanceSelection'

import './instance-config.css'

const userSelector = (state: RootState) => state.user

export function InstanceConfig () {
  const { user } = useAppSelector(userSelector)
  const [selectedInstanceId, setSelectedInstanceId] = useState<string>('')

  const isViewMode = !hasRole(AuthRole.ADMIN, user)

  return (
      <>
        <div className="md:w-1/2 w-full m-auto mt-2 items-center mb-4">
          <div className="mb-4">
              <h1 className="py-5 eds-type--title-1">
                  Instance configurator
                </h1>
            </div>
          <InstanceSelection selectedInstanceId={selectedInstanceId} setSelectedInstanceId={setSelectedInstanceId} isViewMode={isViewMode} />
        </div>
        {selectedInstanceId !== '' && (
            <div className="flex justify-center items-center">
                <InstanceEditor instanceId={selectedInstanceId} setSelectedInstanceId={setSelectedInstanceId} isViewMode={isViewMode} />
            </div>
        )}
      </>
  )
}
