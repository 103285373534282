import { Button, Table } from '@nike/eds'
import { CustomTextField } from 'pages/instance-config/components/CustomTextField'
import { useState } from 'react'

export interface Entry {
  key: string
  value: string
}

interface KeyValueEditorProps {
  entries: Entry[]
  updateEntries: (entries: Entry[]) => void
  entryTypeDisplayName: string
  entryValueLabel: string
  readOnly: boolean
  errors?: string
  clearErrors: () => void
}

export const toEntryArray = (data: Record<string, string>): Entry[] => {
  return Object.keys(data).length > 0
    ? Object.entries(data).map(([k, v]) => ({ key: k, value: v }))
    : []
}

export const hasDuplicates = (entries: Entry[]): boolean => {
  const entryKeys = entries.map(entry => entry.key)
  return entryKeys.some((key, index) => entryKeys.indexOf(key) !== index)
}

export const KeyValueEditor = ({ entries, updateEntries, entryTypeDisplayName, entryValueLabel, readOnly, errors, clearErrors }: KeyValueEditorProps) => {
  const [localValues, setLocalValues] = useState<Entry[]>(entries)

  const handleEntryChange = (index: number, key: string, value: string) => {
    clearErrors()
    const newEntries = changeEntry(index, key, value)
    setLocalValues(newEntries)
  }

  const changeEntry = (index: number, key: string, value: string) => {
    const newEntries = [...localValues]
    newEntries[index] = { key, value }
    return newEntries
  }

  const handleAddEntry = () => {
    setLocalValues([...localValues, { key: '', value: '' }])
  }

  const handleRemoveEntry = (index: number) => {
    clearErrors()
    const newEntries = [...localValues]
    newEntries.splice(index, 1)
    setLocalValues(newEntries)
    updateEntries(newEntries)
  }

  return (
    <div>
      {localValues.length > 0
        ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>{entryValueLabel}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {localValues.map((entry, index) => (
                  <tr key={index}>
                    <td>
                      <CustomTextField
                        width='full'
                        value={entry.key}
                        onChange={(e) => { handleEntryChange(index, e.target.value, entry.value) }}
                        onBlur={(e) => { updateEntries(changeEntry(index, e.target.value, entry.value)) }}
                        readOnly={readOnly}
                        label=""
                        id={`param_${entry.value}`} />
                    </td>
                    <td>
                      <CustomTextField
                        width='full'
                        value={entry.value}
                        onChange={(e) => { handleEntryChange(index, entry.key, e.target.value) }}
                        onBlur={(e) => { updateEntries(changeEntry(index, entry.key, e.target.value)) }}
                        readOnly={readOnly}
                        label=""
                        id={`param_${entry.value}`} />
                    </td>
                    <td>
                      <Button disabled={readOnly} onClick={() => { handleRemoveEntry(index) }}>Remove</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {errors && <div className='flex items-center gap-2'><p className='text-red-500 ml-2'>{errors}</p> <br /></div>}
          </>
          )
        : (
            <div style={{ width: '35vw' }}></div>
          )}
      <br />
      <div className='mb-4'>
        <Button disabled={readOnly} onClick={handleAddEntry}>Add {entryTypeDisplayName}</Button>
      </div>
    </div>
  )
}
