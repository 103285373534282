const AuthConfig = {
  oidc: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    issuer: process.env.REACT_APP_OKTA_OAUTH_URL,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid'],
    pkce: true,
    disableHttpsCheck: false
  }
}

export default AuthConfig
