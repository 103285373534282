import { Button, Modal } from '@nike/eds'
// import { ParameterDisplay } from 'components/parameter-display'
import React from 'react'
import { type AuditEventDTO } from 'types/AuditEvent'
import { formatUnixTimestamp } from 'utils/DateTools'

interface AuditEventModalProps {
  auditEvent: AuditEventDTO
  closeModal: () => void
}

export const AuditEventModal = ({ auditEvent, closeModal }: AuditEventModalProps) => {
  const modalHeader = (
    <div className="flex-grow">
        <span>EventId: {auditEvent.auditEventId}</span> <br />
    </div>
  )

  const modalFooter = (
        <div className="relative">
            <div className={'flex justify-between'}>
                <Button onClick={closeModal}>Close</Button>
            </div>
        </div>
  )
  return (
    <Modal className={'hidden'} isOpen={true} onDismiss={closeModal} headerSlot={modalHeader} footerSlot={modalFooter} hideFade={true}>
      <div className="flex flex-col p-4 bg-white mb-4" key={`div_${auditEvent.auditEventId}`}>
          <span>Author: {auditEvent.userEmail}</span> <br />
          <span>Event-type: {auditEvent.type !== '' ? `${auditEvent.type}` : 'undefined'}</span>  <br />
          <span>Start-time: {auditEvent.timestamp > 0 ? `${formatUnixTimestamp(auditEvent.timestamp)}` : 'undefined'}</span> <br/>
          <span className="float-left">{auditEvent.message}</span> <br/>
           <div className={'flex '}>
               {/* Event data: */}
          {/* {auditEvent.actions.map((action, index) => ( */}
          {/*  Object.entries(action.parameters).length > 0 && */}
          {/*  <ParameterDisplay key={index} title={action.name} subtext={action.description} /> */}
          {/* ))} */}
           </div>
      </div>
    </Modal>
  )
}
