import { mainSplitApi } from 'redux/store/mainSplitApi'
import { type ActionResult } from 'types'

export const actionResultApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getActionResult: builder.query<ActionResult, { workflowId: string, executionId: string, actionId: string }>({
      query: ({ workflowId, executionId, actionId }) => {
        return {
          url: `/results/${workflowId}/${executionId}/${actionId}`
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useGetActionResultQuery } = actionResultApi
