import { Button } from '@nike/eds'
import { useState } from 'react'

import { CustomTextField } from './CustomTextField'

interface ProfileTabProps {
  profileName: string
  updateProfileName: (newName: string, oldName: string) => void
  onDeleteProfile: (profileName: string) => void
  isViewMode: boolean
}

export const ProfileTab = ({ profileName, updateProfileName, onDeleteProfile, isViewMode }: ProfileTabProps) => {
  const [localProfileName, setLocalProfileName] = useState<string>(profileName)
  const [profileError, setProfileError] = useState<string>('')

  return (
    <>
      <CustomTextField
        width='half'
        id='editProfileName'
        onChange={(e) => {
          setProfileError('')
          setLocalProfileName(e.target.value)
        }}
        onBlur={(e) => {
          if (e.target.value === '') {
            setProfileError('Profile name cannot be empty')
            return
          }
          updateProfileName(e.target.value, profileName)
        }}
        readOnly={isViewMode}
        value={localProfileName}
        label="Profile name"
        hasErrors={profileError !== ''}
        errorMessage={profileError}
      />
      <br />
      {!isViewMode && (
        <Button onClick={() => { onDeleteProfile(profileName) }}>Delete profile</Button>
      )}
    </>
  )
}
