import { Card, Icon, TextField } from '@nike/eds'
import { useGetExecutionsQuery } from 'api/execution'
import { Paging } from 'components/paging'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type WorkflowExecutionDTO } from 'types'

import { ExecutionLine } from './components/execution-line'

export function ExecutionOverview () {
  const [currentExecution, setCurrentExecution] = useState<WorkflowExecutionDTO | undefined>(undefined)
  const navigate = useNavigate()

  const { data: executions } = useGetExecutionsQuery()

  const [showExecutions, setShowExecutions] = useState<WorkflowExecutionDTO[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState<number>(0)
  const itemsPerPage = 10

  const [searchValue, setSearchValue] = useState<string>('')

  const showExecution = useCallback((execution: WorkflowExecutionDTO) => {
    setCurrentExecution(execution)
    navigate(`/execution/${execution.id}`)
  }, [])

  useEffect(() => {
    if (currentExecution != null) {
      const updatedExecution = executions?.find(execution => execution.id === currentExecution.id)
      if (updatedExecution !== undefined) {
        setCurrentExecution(updatedExecution)
      }
    }
  }, [executions, currentExecution])

  useEffect(() => {
    const filteredExecutions = filterExecutions()
    if (filteredExecutions !== undefined) {
      const sortedExecutions = sortArray(filteredExecutions)

      const endOffset = itemOffset + itemsPerPage
      setShowExecutions(sortedExecutions.slice(itemOffset, endOffset))
      setPageCount(Math.ceil(sortedExecutions.length / itemsPerPage))
    }
  }, [executions, searchValue, itemOffset])

  const sortArray = (arr: WorkflowExecutionDTO[]): WorkflowExecutionDTO[] => {
    return arr.slice().sort((a, b) => {
      if (a.startTime === -1 && a.endTime === -1) {
        return -1
      } if (a.endTime !== -1 && b.endTime !== -1) {
        return new Date(b.endTime).getTime() - new Date(a.endTime).getTime()
      } if (a.endTime !== -1 && b.startTime !== -1 && b.endTime === -1) {
        return new Date(b.startTime).getTime() - new Date(a.endTime).getTime()
      } if (a.startTime !== -1 && a.endTime === -1 && b.endTime !== -1) {
        return new Date(b.endTime).getTime() - new Date(a.startTime).getTime()
      } if (a.startTime !== -1 && a.endTime === -1 && b.startTime !== -1 && b.endTime === -1) {
        return new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
      } else {
        return 0
      }
    })
  }

  const onChangeSearchValue = (value: string) => {
    setSearchValue(value)
  }

  const filterExecutions = () => {
    if (executions !== undefined) {
      if (searchValue.length > 0) {
        return executions.slice().filter(ex =>
          (ex.workflowName.toLowerCase()).includes(searchValue.toLowerCase()) ||
          (ex.initiatorName.toLowerCase().includes(searchValue.toLowerCase())) ||
          (ex.actionExecutions[0].parameters._instanceName.toLowerCase().includes(searchValue.toLowerCase())) ||
          (ex.status.toLowerCase().includes(searchValue.toLowerCase())))
      } else {
        return executions
      }
    }
  }

  return (
    <div className="md:w-1/2 w-full m-auto mt-2">
      <div className="flex items-center place-content-between mb-4">
        <h1 className="py-5 eds-type--title-1">
          Executions
        </h1>
      </div>
      <div className='mb-8'>
          <TextField
            id='workflows'
            value={searchValue}
            onChange={(e) => { onChangeSearchValue(e.target.value) }}
            label=''
            beforeSlot={<Icon name='Search' />}
            afterSlot={<Icon name='Close' onClick={() => { setSearchValue('') }} />}
          />
        </div>

      {showExecutions !== undefined && showExecutions.length !== 0
        ? (<>
          {showExecutions.map((execution: WorkflowExecutionDTO) => (

            <div key={`div_${execution.id}`} onClick={() => { showExecution(execution) }}>
              <ExecutionLine key={execution.id} execution={execution} />
            </div>
          ))}
          <div>
            {executions !== undefined && (
              <Paging items={executions} itemsPerPage={itemsPerPage} pageCount={pageCount} setItemOffset={setItemOffset} />
            )}
          </div>
        </>)
        : <Card className={'w-full border'}>
          <span className={'text-center'}>No executions found</span>
        </Card>
      }
    </div>
  )
}
