import { Snack, Snackbar } from '@nike/eds'
import { useEffect, useState } from 'react'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { type SnackbarState, type SnackbarStatus } from 'redux/slices/snackbar.slices'
import { dispatch, useAppSelector } from 'redux/store'
import './snackbar.css'

export const SnackBar = () => {
  const snackbarState = useAppSelector((state: { snackbar: SnackbarState }) => state.snackbar)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState<SnackbarStatus>('neutral')
  let timerHandle: string | number | NodeJS.Timeout | null | undefined = null

  useEffect(() => {
    if (snackbarState.message !== '') {
      setMessage(snackbarState.message)
      setStatus(snackbarState.status)
      timerHandle = setTimeout(() => {
        reset()
      }, 5000)
    }
    return () => {
      if (timerHandle) {
        clearTimeout(timerHandle)
      }
    }
  }, [snackbarState])

  const reset = () => {
    setMessage('')
    setStatus('neutral')
    dispatch(showSnackbar('', 'neutral'))
  }

  return (
    <>
      {message !== '' &&
        <Snackbar onClick={reset}>
          <Snack id={'snackbar-snack'} status={status}>
            {message}
          </Snack>
        </Snackbar>
      }
    </>
  )
}
