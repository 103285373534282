import { Icon } from '@nike/eds'
import { ProgressBar } from 'components/progressbar'
import type { ReactNode } from 'react'
import { ActionStatus, ActionType, type WorkflowExecutionDTO, WorkflowStatus } from 'types'

import { SpinnerTypes, StatusSpinner } from '../status-spinner'

interface ExecutionProps {
  execution: WorkflowExecutionDTO
}

const renderStatusProperties = (execution: WorkflowExecutionDTO, progressPercent: number): ReactNode => {
  const errorMessage = execution.errorMessage != null ? execution.errorMessage : 'Failed due to a technical error!'
  switch (execution.status) {
    case WorkflowStatus.NOT_STARTED:
      return <div className="text-gray-600 mb-2">Pending...</div>

    case WorkflowStatus.FAILED:
      return <div className="text-gray-600 mb-2">Failed</div>

    case WorkflowStatus.IN_PROGRESS:
      return <div className="text-gray-600 mb-2">{`Start: ${execution.startTimeFormatted}`}</div>

    case WorkflowStatus.COMPLETED:
    case WorkflowStatus.CANCELLED:
      return <div className='flex justify-between'>
        <div className="text-gray-600 mb-2">{`Start: ${execution.startTimeFormatted}`}</div>
        <div className="text-gray-600 mb-2">{`End: ${execution.endTimeFormatted}`}</div>
        <div className="text-gray-600 mb-2">{`Duration: ${execution.duration}`}</div>
      </div>

    case WorkflowStatus.INTERNAL_ERROR:
      return <span className={'text-gray-600 whitespace-pre-line mb-2'} style={{ wordBreak: 'break-all' }}>
        {errorMessage}
      </span>
  }
}

const renderStatusIcon = (execution: WorkflowExecutionDTO): ReactNode => {
  switch (execution.status) {
    case WorkflowStatus.NOT_STARTED:
      return <StatusSpinner type={SpinnerTypes.NOT_STARTED} />
    case WorkflowStatus.IN_PROGRESS:
      return <StatusSpinner type={SpinnerTypes.IN_PROGRESS} />
    case WorkflowStatus.COMPLETED:
      return <div className="flex-none w-12 h-12 bg-green-300 flex items-center justify-center rounded-full mr-4"><Icon name="Check" size="m" /></div>
    case WorkflowStatus.FAILED:
      return <div className="flex-none w-12 h-12 bg-red-300 flex items-center justify-center rounded-full mr-4"><Icon name="Close" size="m" /></div>
    case WorkflowStatus.CANCELLED:
      return <div className="flex-none w-12 h-12 bg-red-300 flex items-center justify-center rounded-full mr-4"><Icon name="Stop" size="m" /></div>
    case WorkflowStatus.INTERNAL_ERROR:
      return <div className="flex-none w-12 h-12 bg-orange-300 flex items-center justify-center rounded-full mr-4"><Icon name="Alert" size="m" /></div>
  }
}

const renderActionProgress = (execution: WorkflowExecutionDTO): ReactNode => {
  const currentActionIndex = execution.actionExecutions.findIndex((action) => action.status === ActionStatus.IN_PROGRESS)
  if (currentActionIndex === -1) {
    return null
  }
  const currentAction = execution.actionExecutions[currentActionIndex]
  if (currentAction.type === ActionType.BULK_API_CALL) {
    return <div>
      <span>Current action #{currentActionIndex + 1}</span>
      <span className="pl-4">successes: {currentAction.nrSuccesses}</span>
      <span className="pl-4">failures: {currentAction.nrFailures}</span>
    </div>
  }
}

export const ExecutionLine = ({ execution }: ExecutionProps) => {
  const completedActions = execution.actionExecutions.filter((action) => action.status === ActionStatus.COMPLETED).length
  const totalActions = execution.actionExecutions.length
  const progressPercent = (completedActions / totalActions) * 100

  return (
        <div className="flex items-center p-4 bg-white shadow rounded mb-4 cursor-pointer" >
          { renderStatusIcon(execution) }
          <div className="flex-grow">
            <div className="flex justify-between">
              <div className="font-bold">{execution.workflowName} - {execution.actionExecutions[0].parameters._instanceProfile}@{execution.actionExecutions[0].parameters._instanceName}</div>
              { renderActionProgress(execution) }
              { ![WorkflowStatus.FAILED, WorkflowStatus.INTERNAL_ERROR, WorkflowStatus.CANCELLED].includes(execution.status) &&
                <div>{`${Math.round(progressPercent)}% completed`}</div>
              }
              { execution.status === WorkflowStatus.CANCELLED &&
                <div>Cancelled</div>
              }
            </div>
            <div className="text-gray-600">{execution.initiatorName}</div>
            <div className="mt-2">
              { renderStatusProperties(execution, progressPercent) }
              <ProgressBar value={progressPercent} max={100}/>
            </div>
            </div>
          </div>
  )
}
