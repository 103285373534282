import { Divider, Spinner, TableCell, TableHeading } from '@nike/eds'
import { useGetAuditEventsQuery } from 'api/auditEvent'
import classNames from 'classnames'
import { Search } from 'components/search'
import { useEffect, useState } from 'react'
import { type AuditEventDTO } from 'types/AuditEvent'
import { formatUnixTimestamp } from 'utils/DateTools'

import { AuditEventModal } from './components'
import { FieldType, type FieldDefinition, type SearchOption } from './types'

const oneDayInMillis = 24 * 60 * 60 * 1000
const last24H = (new Date().getTime() - oneDayInMillis).toString()
const now = new Date().getTime().toString()

const searchMap: Record<string, string> = {
  after: last24H,
  before: now,
  userEmail: '',
  workflowId: '',
  eventType: '',
  snowTicketRef: ''
}

export function AuditView () {
  const [currentAuditEvent, setCurrentAuditEvent] = useState<AuditEventDTO | undefined>(undefined)
  const [showModal, setShowModal] = useState(false)
  const [searchContext, setSearchContext] = useState<Record<string, string>>(searchMap)
  const { data: events, isLoading } = useGetAuditEventsQuery(searchContext, { skip: searchContext.userEmail === undefined || searchContext.userEmail === '' })

  const retrieveUserEmail = () => {
    const storedJson = localStorage.getItem('okta-token-storage')
    const storedObject = storedJson != null ? JSON.parse(storedJson) : null
    if (storedObject?.accessToken?.accessToken != null && storedObject?.accessToken?.claims !== '' && storedObject?.accessToken?.claims?.sub !== '') {
      setSearchContext(prevState => ({ ...prevState, userEmail: storedObject.accessToken.claims.sub }))
    }
  }

  useEffect(() => {
    if (searchContext.userEmail === undefined || searchContext.userEmail === '') {
      retrieveUserEmail()
    }
  }, [])

  const openModal = (auditEventId: string) => {
    setCurrentAuditEvent(events?.find(event => event.auditEventId === auditEventId))
    setShowModal(true)
  }

  const closeModal = () => { setShowModal(false) }

  const getValue = (event: AuditEventDTO, key: string) => {
    if (key === 'auditEventId') {
      return event.auditEventId
    }
    if (key === 'userEmail') {
      return event.userEmail
    }
    if (key === 'type') {
      return event.type
    }
    if (key === 'timestamp') {
      return formatUnixTimestamp(event.timestamp)
    }
  }

  const updateSearchContext = (newSearchContext: Record<string, string>) => {
    const updateContext: Record<string, string> = {}
    if (newSearchContext.after === '') {
      updateContext.after = last24H
    }
    if (newSearchContext.before === '') {
      updateContext.before = new Date().getTime().toString()
    }
    setSearchContext({ ...newSearchContext, ...updateContext })
  }

  const refreshEvents = (newSearchContext: Record<string, string>) => {
    const refreshContext: Record<string, string> = {}
    refreshContext.after = (new Date().getTime() - oneDayInMillis - 10).toString()
    refreshContext.before = new Date().getTime().toString()
    setSearchContext({ ...newSearchContext, ...refreshContext })
  }

  const fieldDefinitions: FieldDefinition[] = [
    {
      header: 'Event ID',
      type: FieldType.TEXT,
      accessor: 'auditEventId'
    },
    {
      header: 'User Email',
      type: FieldType.TEXT,
      accessor: 'userEmail'
    },
    {
      header: 'Event timestamp',
      type: FieldType.DATETIME,
      accessor: 'timestamp'
    },
    {
      header: 'Event Type',
      type: FieldType.TEXT,
      accessor: 'type'
    }
  ]

  const searchOptions: SearchOption[] = [
    {
      label: 'Author',
      accessor: 'userEmail',
      default: '',
      type: FieldType.TEXT,
      values: []
    }, {
      label: 'Before: (dd/MM/YYYY)',
      accessor: 'before',
      default: '',
      type: FieldType.DATETIME,
      values: []
    }, {
      label: 'After: (dd/MM/YYYY)',
      accessor: 'after',
      default: '',
      type: FieldType.DATETIME,
      values: []
    }, {
      label: 'Event Type',
      accessor: 'eventType',
      default: '',
      type: FieldType.TEXT,
      values:
        [
          { label: 'Workflow created', value: 'WORKFLOW_CREATED' },
          { label: 'Workflow updated', value: 'WORKFLOW_UPDATED' },
          { label: 'Workflow deleted', value: 'WORKFLOW_DELETED' },
          { label: 'Execution started', value: 'WORKFLOW_EXECUTION_STARTED' },
          { label: 'Execution successful', value: 'WORKFLOW_EXECUTION_SUCCESSFUL' },
          { label: 'Execution failed', value: 'WORKFLOW_EXECUTION_FAILED' },
          { label: 'Execution cancelled', value: 'WORKFLOW_EXECUTION_CANCELLED' },
          { label: 'Execution error', value: 'WORKFLOW_EXECUTION_INTERNAL_ERROR' }
        ]
    },
    {
      label: 'Workflow Id',
      accessor: 'workflowId',
      default: '',
      type: FieldType.TEXT,
      values: []
    },
    {
      label: 'Reference (Snow)',
      accessor: 'reference',
      default: '',
      type: FieldType.TEXT,
      values: []
    }
  ]

  return (
    <>
      <div className="w-full m-auto mt-2">
        <div className="flex items-center place-content-between mb-4">
          <h1 className="py-5 eds-type--title-1">
            Event history
          </h1>
        </div>
        <div className='mb-8 px-2'>
          <Search searchContext={searchContext} updateSearch={updateSearchContext} searchOptions={searchOptions} refresh={refreshEvents} />
          <Divider />
        </div>
        <div className="flex flex-col">
          {isLoading
            ? (<div className='bg-white flex justify-center py-10 shadow rounded '>
              <Spinner size='large' />
            </div>)
            : <table className={classNames('eds-table')}>
              <thead>
                <tr>
                  {fieldDefinitions.map(fieldDef => (
                    <TableHeading key={`table-header-${fieldDef.header}`}>{fieldDef.header}</TableHeading>
                  ))}
                </tr>
              </thead>
              <tbody>
                {events !== undefined && events.length > 0
                  ? <>
                    {events.map(row => (
                      <tr key={`tr-${row.auditEventId}`} onClick={() => { openModal(row.auditEventId) }}>
                        {fieldDefinitions.map((fieldDef) => {
                          return (
                            <TableCell key={`tr-cell-${row.auditEventId}-${fieldDef.header}`} hasError={false}>{getValue(row, fieldDef.accessor)}</TableCell>
                          )
                        })}
                      </tr>
                    ))}
                    {/* <Paging currentPage={currentPage} totalPages={totalPages} previousPage={previousPage} nextPage={nextPage} goToFirstPage={goToFirstPage} goToLastPage={goToLastPage} /> */}
                  </>
                  : <tr className={'w-full border text-center'}>
                    <TableCell colSpan={4} className={'font-semibold text-justify'} hasError={false}>No items to display.</TableCell>
                  </tr>
                }
              </tbody>
            </table>
          }
        </div>
      </div>
      {(showModal && currentAuditEvent != null) &&
        <AuditEventModal auditEvent={currentAuditEvent} closeModal={closeModal} />
      }
    </>
  )
}
