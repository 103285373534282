import { Chip, Icon } from '@nike/eds'

interface CategoryChipsProps {
  categories: string[]
  deleteChip?: (category: string) => void
}

export const CategoryChips = ({ categories, deleteChip }: CategoryChipsProps) => {
  const renderChips = () => {
    return categories.map((category, index) => {
      if (category === '') { return null }
      return <Chip
        as="button"
        className='m-2'
        key={index}
        disabled={deleteChip === undefined}
        onClick={() => { deleteChip && deleteChip(category) }}
        afterSlot={deleteChip ? <Icon name='Close' /> : <></>}
      >
        {category}
      </Chip>
    })
  }

  return (
    <>{renderChips()}</>
  )
}
