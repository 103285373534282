import { Icon, Tooltip } from '@nike/eds'

import './infowrapper.css'

export const InfoWrapper = ({ children, tooltip }: any) => {
  return (
    <>
      {children}
      <Tooltip
        bodySlot={tooltip}
        placement={'right'}
        enableFocus >
        <Icon
          name="Info"
          size="s"
          backgroundShape="circle"
          backgroundColor="var(--eds-color-grey-2)" />
      </Tooltip>
    </>
  )
}
