export interface Instance {
  id: string
  name: string
  baseUrl: string
  profiles: Record<string, Profile>
}

export interface Profile {
  oauthCredentials: Record<string, OauthCredentials>
  tags: Record<string, string>
}

export interface OauthCredentials {
  clientId: string
  clientSecret: string
  clientAuthenticationMethod: string
  authorizationGrantType: string
  scopes: string[]
  tokenUri: string
  username: string
  password: string
}

export const emptyOauthCredentials: OauthCredentials = {
  clientId: '',
  clientSecret: '',
  clientAuthenticationMethod: 'client_credentials_basic',
  authorizationGrantType: 'password',
  scopes: [],
  tokenUri: '',
  username: '',
  password: ''
}

export const emptyProfile: Profile = {
  oauthCredentials: {},
  tags: {}
}

export const emptyInstanceConfig: Instance = { id: '', name: '', baseUrl: '', profiles: {} }
