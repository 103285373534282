import { configureStore } from '@reduxjs/toolkit'
import { useSelector, type TypedUseSelectorHook } from 'react-redux'
import { reducer as snackbar } from 'redux/slices/snackbar.slices'
import { reducer as user } from 'redux/slices/user.slices'

import { mainSplitApi } from './mainSplitApi'

export const store = configureStore({
  reducer: {
    user,
    snackbar,
    [mainSplitApi.reducerPath]: mainSplitApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      // Required for user
      serializableCheck: false
    }).concat(mainSplitApi.middleware)
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const dispatch = store.dispatch
